import React, { useState, useEffect } from "react";
import { db } from "../firebase"
import Header from "./header";
import { FaRegEye } from "react-icons/fa";
import { IoShareSocialOutline } from "react-icons/io5";
import { FaRegClock } from "react-icons/fa";
import Footer from "./footer";
import { Link } from "react-router-dom"

const Blog = ({ posts, selectedLanguage }) => {

    const [loading, setLoading] = useState(false)

    const sortedPublications = [...(posts ?? [])]?.sort((a, b) => b?.timestamp - a?.timestamp);

    const [postCount, setPostCount] = useState(3)

    const showMore = () => {
        setPostCount(postCount + 3)
    }

    const [buttons, setButtons] = useState(true)

    useEffect(() => {
        if (postCount > posts.length) {
            setButtons(false);
        } else {
            setButtons(true);
        }
    }, [postCount, posts.length]);

    return (
        <>
            <Header />
            <div className="border-t-2 pt-20 border-gray-300">
                {loading ? (
                    <div className="flex items-center h-screen justify-center">
                        <p className="text-gray-600 font-semibold">
                            {selectedLanguage === "es-ES" ? "Cargando publicaciones..." : "Loading posts..."}
                        </p>
                    </div>
                ) : (
                    <div className="flex flex-col items-center pb-20 space-y-4">
                        {(sortedPublications ?? []).slice(0, postCount)?.map((publication, index) => (
                            <Link className="flex items-center space-y-6 space-x-4 bg-white rounded-lg shadow p-4 w-11/12 sm:w-3/4" key={index} to={`/post/${publication?.id}`}>
                                <div className="flex">
                                    <img className="w-24 h-24 rounded-lg" src={publication.image} alt={publication.title} />
                                    <div className="flex-grow px-6">
                                        <div class="break-words text-balance mt-2 font-sans text-base text-gray-500 dark:text-gray-400 selection:bg-indigo-500/50 selection:text-gray-900 dark:selection:bg-indigo-600/10 dark:selection:text-indigo-200">
                                            <p class="font-medium">{selectedLanguage === "es-ES" ? publication.title : publication?.english_title}</p>
                                            <p class="font-light pt-1 line-clamp-2">{selectedLanguage === "es-ES" ? publication.description : publication?.english_description}</p>
                                        </div>
                                        <div className="flex items-center space-x-5 mt-3">
                                            <p className="text-sm flex items-center text-gray-600"><FaRegEye className="w-3 h-auto mr-1" /> {publication?.likes}</p>
                                            <p className="text-sm flex items-center text-gray-600"><IoShareSocialOutline className="w-3 h-auto mr-1" /> {publication?.shares} </p>
                                            <p className="text-sm flex items-center text-gray-600"><FaRegClock className="w-3 h-auto mr-1" />  </p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                )}
            </div>
            <Footer />
        </>
    )
}

export default Blog;