import React from 'react';
import Header from './header';
import Footer from './footer';

const Guide = ({ selectedLanguage }) => {
    return (
<>
<Header/>
            <div className="container p-32">
                <p className="text-4xl font-semibold mb-8 text-indigo-700">
                    {selectedLanguage === "es-ES" ? "Guía para usar Vortex - Motor de Búsqueda Académico" : "Guide to Using Vortex - Academic Search Engine"}
                </p>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    <section className="bg-white my-12 rounded-lg shadow-md p-6 border-l-8 border-blue-500">
                        <div className="flex items-center mb-4">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-blue-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <h2 className="text-2xl font-bold">
                                {selectedLanguage === "es-ES" ? "Introducción" : "Introduction"}
                            </h2>
                        </div>
                        <ul className="list-disc list-inside text-lg">
                            <li>
                                {selectedLanguage === "es-ES"
                                    ? "Combina Azure Cognitive Search con Azure OpenAI API"
                                    : "Combines Azure Cognitive Search with OpenAI API"}
                            </li>
                            <li>
                                {selectedLanguage === "es-ES"
                                    ? "Analiza artículos académicos y sus resúmenes TLDR"
                                    : "Analyzes academic papers and their TLDR summaries"}
                            </li>
                            <li>
                                {selectedLanguage === "es-ES"
                                    ? "Funciona mejor con preguntas bien formuladas"
                                    : "Works best with well-formed questions"}
                            </li>
                        </ul>
                    </section>

                    <section className="bg-white my-12 rounded-lg shadow-md p-6 border-l-8 border-purple-500">
                        <div className="flex items-center mb-4">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-purple-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
                            </svg>
                            <h2 className="text-2xl font-bold">
                                {selectedLanguage === "es-ES" ? "Preguntas Cerradas" : "Closed Questions"}
                            </h2>
                        </div>
                        <ul className="list-disc list-inside text-lg">
                            <li>
                                {selectedLanguage === "es-ES"
                                    ? "Las preguntas cerradas se pueden responder con 'sí', 'quizás' o 'no'"
                                    : "Closed questions can be answered with 'yes,' 'maybe,' or 'no'"}
                            </li>
                            <li>
                                {selectedLanguage === "es-ES"
                                    ? "Para hacer una pregunta cerrada, ingresa tu pregunta en la barra de búsqueda y presiona enter o haz clic en el botón de búsqueda"
                                    : "To ask a closed question, enter your question in the search bar and press enter or click the search button"}
                            </li>
                            <li>
                                {selectedLanguage === "es-ES"
                                    ? "El motor de búsqueda devolverá una lista de artículos etiquetados con 'sí', 'no' o 'quizás' según su relevancia para tu pregunta"
                                    : "The search engine will return a list of papers labeled with 'yes,' 'no,' or 'maybe' based on their relevance to your question"}
                            </li>
                        </ul>
                    </section>

                    <section className="bg-white rounded-lg shadow-md p-6 border-l-8 border-green-500">
                        <div className="flex items-center mb-4">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-green-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <h2 className="text-2xl font-bold">
                                {selectedLanguage === "es-ES" ? "Preguntas Abiertas" : "Open-Ended Questions"}
                            </h2>
                        </div>
                        <ul className="list-disc list-inside text-lg">
                            <li>
                                {selectedLanguage === "es-ES"
                                    ? "Las preguntas abiertas requieren una respuesta más detallada"
                                    : "Open-ended questions require more detailed answers"}
                            </li>
                            <li>
                                {selectedLanguage === "es-ES"
                                    ? "El motor de búsqueda analiza los resúmenes TLDR de los artículos relevantes"
                                    : "The search engine analyzes the TLDRs of relevant papers"}
                            </li>
                            <li>
                                {selectedLanguage === "es-ES"
                                    ? "Combina esta información con su base de conocimientos existente"
                                    : "Combines this information with its existing knowledge base"}
                            </li>
                        </ul>
                    </section>

                    <section className="bg-white rounded-lg shadow-md p-6 border-l-8 border-yellow-500">
                        <div className="flex items-center mb-4">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-yellow-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                            </svg>
                            <h2 className="text-2xl font-bold">
                                {selectedLanguage === "es-ES" ? "Consejos para Mejores Resultados" : "Tips for Better Results"}
                            </h2>
                        </div>
                        <ul className="list-disc list-inside text-lg">
                            <li>
                                {selectedLanguage === "es-ES"
                                    ? "Sé específico y claro al hacer preguntas"
                                    : "Be specific and clear when asking questions"}
                            </li>
                            <li>
                                {selectedLanguage === "es-ES"
                                    ? "Usa oraciones completas y gramática adecuada"
                                    : "Use complete sentences and proper grammar"}
                            </li>
                            <li>
                                {selectedLanguage === "es-ES"
                                    ? "Evita usar términos de búsqueda simples, ya que el motor de búsqueda está optimizado para preguntas"
                                    : "Avoid using simple search terms, as the search engine is optimized for questions"}
                            </li>
                            <li>
                                {selectedLanguage === "es-ES"
                                    ? "Si no encuentras una respuesta satisfactoria, intenta reformular tu pregunta"
                                    : "If you don't find a satisfactory answer, try rephrasing your question"}
                            </li>
                        </ul>
                    </section>
                </div>
            </div>
<Footer/>
</>
    );
};

export default Guide;