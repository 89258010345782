import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, onAuthStateChanged } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyBaJqMGmEFqLvaCzgXSxoYaCZ1zaTx2FH0",
    authDomain: "vortex-cloud-e76e3.firebaseapp.com",
    projectId: "vortex-cloud-e76e3",
    storageBucket: "vortex-cloud-e76e3.appspot.com",
    messagingSenderId: "424526568789",
    appId: "1:424526568789:web:727cdab451dde0c3f6a3dc",
    measurementId: "G-X222KEJJER"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const googleProvider = new GoogleAuthProvider();

export { onAuthStateChanged };