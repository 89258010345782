import React, {useEffect, useState} from "react"
import { ImQuotesLeft } from "react-icons/im";
import { IoIosDocument } from 'react-icons/io';
import { FaSpinner } from 'react-icons/fa';
import { doc, deleteDoc, collection } from 'firebase/firestore';
import { db } from "../firebase";
import { CheckCircleIcon, QuestionMarkCircleIcon, ClipboardIcon, XCircleIcon, NewspaperIcon, AcademicCapIcon, SparklesIcon, BookmarkIcon, UserCircleIcon, MenuAlt3Icon, ShareIcon, AdjustmentsIcon, InformationCircleIcon, ArrowDownIcon, XIcon, ChatAltIcon } from "@heroicons/react/solid";


const List = ({ selectedLanguage, savedDocs, user }) => {

    const sortedPapers = savedDocs?.sort((a, b) => b.timestamp - a.timestamp)

    const [expandedPapers, setExpandedPapers] = useState({});

    const toggleText = (index) => {
        setExpandedPapers(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    const [citationPopup, setCitationPopup] = useState({ show: false, citation: '' });

    const formatCitationAPA = (paper) => {
        const authors = paper?.author || "";
        const year = paper?.year || "";
        const title = paper?.title || "";
        const journal = paper?.journal?.name || "";
        const volume = paper?.volume || "";
        const pages = paper?.pages || "";

        return `${authors} (${year}). ${title}. ${journal}, ${volume}, ${pages}`;
    };

    const showCitationPopup = (paper) => {
        const citation = formatCitationAPA(paper);
        setCitationPopup({ show: true, citation });
    };

    const closeCitationPopup = () => {
        setCitationPopup({ show: false, citation: '' });
    };

    const handleDelete = async (paper) => {
        const docRef = doc(db, "s3", paper.uid);
        try {
            await deleteDoc(docRef);
            alert("Document Deleted");
        } catch (error) {
            alert(error.message);
        }
    };


    return(
        <>
            <div className="flex justify-center">
                <div className="w-full">
                    <div className="items-center w-full py-5">
                        <div className="flex border-blue-800 pb-2 border-b mx-4 items-center cursor-pointer">
{user? 
                                <>
                                    <div className="p-2">
                                        {savedDocs?.length ?
                                            <p className=" text-sm  font-semibold text-blue-800 ">{savedDocs?.length}</p> :
                                            <FaSpinner className='animate-spin w-4 sm:w-5 h-4 sm:h-5 sm:ml-2 text-gray-500' />
                                        }
                                    </div>
                                    <p className="font-semibold text-blue-800 text-sm">
                                        {selectedLanguage === "es-ES" ? "Papers guardados" : "Saved papers"}</p>
                                </>
                                : <>
                                    Your saved papers will go below
                                </>
}
                        </div>
                    </div>
                    <div className="w-full">
{user? 
                            <>
                                {sortedPapers ?
                                    <>
                                        {sortedPapers?.map((paper, index) => (
                                            <div key={index} className='flex justify-center'>
                                                <div className=''>
                                                    <div className='flex w-full justify-evenly'>
                                                        <a href={paper?.url} target='_blank' className='hover:underline py-2 flex items-center'>
                                                            <div className='w-full'>
                                                                <p className='text-gray-500 text-sm '>{paper?.translatedTitle} ({paper?.year})</p>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </> :
                                    <div className="flex my-12 justify-center">
                                        <FaSpinner className='animate-spin w-4 sm:w-5 h-4 sm:h-5 sm:ml-2 text-gray-500' />
                                    </div>
                                }
                            </> :
                            <div className="flex justify-center">
                            <div className="mx-auto">
                                <BookmarkIcon className="w-8 mx-auto mt-8 h-auto text-gray-600" />
                                <p className="text-center">Please login to see your saved papers</p>
                            </div>
                            </div>
}
                    </div>
                </div>
            </div>
        </>
    )
}

export default List;