import React from 'react';
import Header from "./header"
import Footer from './footer';

const PrivacyPolicy = () => {
    return (
<>
<Header/>
            <div className="max-w-4xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
                <p className="mb-4">Effective Date: April 1, 2024</p>

                <p className="mb-4">
                    This Privacy Policy describes how Konlap Technologies E.I.R.L. ("we", "us" or "our") handles personal information that we collect through our mobile app "Academic Search Engine" (the "App").
                </p>

                <div className="mb-8">
                    <h2 className="text-xl font-bold mb-2">Information We Collect</h2>
                    <p>The only personal information that the App collects is the email address you provide when registering for an account. We do not collect any other personal or sensitive information.</p>
                </div>

                <div className="mb-8">
                    <h2 className="text-xl font-bold mb-2">How We Use Your Information</h2>
                    <p>We use your email address solely for the purpose of providing you with access to the App and its features. We will not use your email for any other purpose, such as sending marketing emails, unless you explicitly opt-in to receive such communications.</p>
                </div>

                <div className="mb-8">
                    <h2 className="text-xl font-bold mb-2">Information Sharing and Disclosure</h2>
                    <p className="mb-2">We do not share, sell, rent, or trade your email address or any other personal information with third parties for their commercial purposes.</p>
                    <p className="mb-2">We may share your personal information with third-party service providers who perform services on our behalf, such as providing hosting and maintenance services, analyzing customer data, and providing customer service. These service providers are contractually obligated to maintain the confidentiality and security of your information.</p>
                    <p>We may also disclose your personal information if required to do so by law or in the good faith belief that such action is necessary to comply with legal obligations, protect and defend our rights or property, or act in urgent circumstances to protect the personal safety of users of the App or the public.</p>
                </div>

                <div className="mb-8">
                    <h2 className="text-xl font-bold mb-2">Data Security</h2>
                    <p className="mb-2">We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no data transmission over the Internet or data storage system can be guaranteed to be 100% secure. Therefore, we cannot guarantee the security of your personal information.</p>
                </div>

                <div className="mb-8">
                    <h2 className="text-xl font-bold mb-2">Data Retention</h2>
                    <p>We will retain your email address for as long as you maintain an account with the App. If you delete your account, we will delete your email address from our records within a reasonable period of time.</p>
                </div>

                <div className="mb-8">
                    <h2 className="text-xl font-bold mb-2">Children's Privacy</h2>
                    <p>The App is not directed to children under the age of 13, and we do not knowingly collect personal information from children under 13. If we learn that we have collected personal information from a child under 13, we will promptly delete such information from our records.</p>
                </div>

                <div className="mb-8">
                    <h2 className="text-xl font-bold mb-2">Changes to This Privacy Policy</h2>
                    <p className="mb-2">We may update this Privacy Policy from time to time. If we make material changes to this Privacy Policy, we will notify you by email or by posting a notice on the App prior to the effective date of the changes.</p>
                    <p>We encourage you to periodically review this Privacy Policy for the latest information on our privacy practices.</p>
                </div>

                <div>
                    <h2 className="text-xl font-bold mb-2">Contact Us</h2>
                    <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>
                    <p>Konlap Technologies E.I.R.L.</p>
                    <p>Email: <a href="mailto:konlaptechs@gmail.com" className="underline">konlaptechs@gmail.com</a></p>
                </div>
            </div>
<Footer/>
</>
    );
};

export default PrivacyPolicy;