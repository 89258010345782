import React, { useState, useEffect } from 'react';
import ReactCountryFlag from 'react-country-flag';

const languages = [
    { code: 'en-US', name: 'English', countryCode: "US" },
    { code: 'es-ES', name: 'Español', countryCode: "ES" },
];  

const Selectlan = ({ showModal, setShowModal, handleSelectLanguage, selectedLanguage, setSelectedLanguage }) => {

    useEffect(() => {
        const savedLanguage = localStorage.getItem('selectedLanguage');
        if (savedLanguage) {
            setSelectedLanguage(savedLanguage);
        } else {
            setShowModal(true);
        }
    }, []); 

    return (
        <>
            {showModal && (
                <div className="fixed z-30 inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
                    <div className="relative top-20 mx-auto p-5 border shadow-lg rounded-md w-4/5 sm:w-1/6 bg-white">
                        <div className="mt-3 text-center">
                            <div className="mt-2 py-8">
                                {languages.map((language) => (
                                    <div key={language.code} className="my-2 flex justify-center">
                                        <button
                                            onClick={() => handleSelectLanguage(language.code)}
                                            className="text-blue-700 hover:bg-blue-100 rounded border-blue-300 border my-3 text-lg flex items-center py-3 px-5 text-lg font-medium hover:text-blue-900"
                                        >
                                            {language.name}
                                            <ReactCountryFlag className='ml-2' countryCode={language.countryCode} svg />
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Selectlan;