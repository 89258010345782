import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "./header";
import Footer from "./footer";

const Post = (props) => {

    const { id } = useParams();
    const posts = props.posts;
    const selectedLanguage = props.selectedLanguage;
    const publication = posts?.find(pub => pub.id === id);

    if (!publication) {
        return <div className="flex justify-center pt-32">Loading</div>;
    }

    return(
        <>
        <Header/>
            <div className="flex justify-center">
                <hr className="w-11/12 pb-6 sm:w-3/4 text-gray-300">
                </hr>
            </div>
            <div className="flex justify-center">
                <div className="w-11/12 sm:w-3/4 pt-4 pb-6">
                    <p className="text-gray-500 font-medium pb-6">{selectedLanguage === "es-ES" ? "Publicado en:" : "Published on:" } {publication?.date?.toDate().toLocaleString()}</p>
                </div>
            </div>
            <div className="flex justify-center">
                <hr className="w-11/12 sm:w-3/4 text-gray-300">
                </hr>
            </div>
            <div className="flex justify-center">
                <div className="w-11/12 sm:w-3/4 pb-32 pt-6">
                    <p className="text-gray-600 text-justify " dangerouslySetInnerHTML={{ __html: selectedLanguage === "es-ES" ? publication.maintext : publication.english_maintext }}></p>
                </div>
            </div>
        <Footer/>
        </>
    )
}

export default Post;