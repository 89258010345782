import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

const FAQItem = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="bg-white rounded-lg shadow-md mb-4 overflow-hidden">
            <button
                className="w-full text-left p-4 flex justify-between items-center focus:outline-none"
                onClick={() => setIsOpen(!isOpen)}
            >
                <span className="font-medium text-gray-800">{question}</span>
                {isOpen ? <ChevronUp className="h-5 w-5 text-gray-500" /> : <ChevronDown className="h-5 w-5 text-gray-500" />}
            </button>
            {isOpen && (
                <div className="p-4 bg-gray-50">
                    <p className="text-gray-700">{answer}</p>
                </div>
            )}
        </div>
    );
};

const FAQComponent = ({ selectedLanguage }) => {
    const translations = {
        'en-US': {
            title: "FAQ",
            subtitle: "Frequently Asked Questions",
            faqData: [
                {
                    question: "What scientific databases do you search?",
                    answer: "We search a wide range of scientific databases including PubMed, Web of Science, and Scopus. Our system is designed to access the most comprehensive and up-to-date scientific information available."
                },
                {
                    question: "It didn't find my paper. Why?",
                    answer: "There could be several reasons, such as the paper being very recent and not yet indexed, or perhaps it's in a database we don't currently access. We're constantly expanding our search capabilities. Please contact our support team with specific details, and we'll investigate further."
                },
                {
                    question: "Do you search full texts, or just abstracts?",
                    answer: "We primarily search abstracts for efficiency, but for many articles, we also have access to full texts. This allows us to provide more in-depth analysis when necessary."
                },
            ]
        },
        'es-ES': {
            title: "Preguntas frecuentes",
            subtitle: "Preguntas más frecuentes",
            faqData: [
                {
                    question: "¿Qué bases de datos científicas usan?",
                    answer: "Buscamos en una amplia gama de bases de datos científicas, y la mayoría de nuestros resultados provienen de Semantic Scholar que recolecta información de PubMed, Web of Science y Scopus."
                },
                {
                    question: "No encontró mi artículo. ¿Por qué?",
                    answer: "Podría haber varias razones, como que el artículo sea muy reciente y aún no esté indexado, o tal vez esté en una base de datos a la que actualmente no tenemos acceso. Estamos constantemente expandiendo nuestras capacidades de búsqueda. Por favor, contacta a nuestro equipo de soporte con detalles específicos y lo investigaremos más a fondo."
                },
                {
                    question: "¿Buscan textos completos o solo resúmenes?",
                    answer: "Principalmente buscamos en resúmenes por eficiencia, pero para muchos artículos también tenemos acceso a textos completos. Esto nos permite proporcionar un análisis más profundo cuando es necesario."
                }
            ]
        }
    };

    const t = translations[selectedLanguage] || translations['en-US'];

    return (
        <div className="bg-gradient-to-br from-blue-50 to-white min-h-screen py-16 px-4 sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto">
                <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl mb-2">{t.title}</h2>
                <p className="text-center text-xl text-gray-600 mb-8">{t.subtitle}</p>
                <div className="space-y-4">
                    {t.faqData.map((item, index) => (
                        <FAQItem key={index} question={item.question} answer={item.answer} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FAQComponent;