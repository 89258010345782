import {useState, useEffect} from "react"
import Header from "./header";
import Footer from "./footer";

const Search = ({ user, signIn, selectedLanguage }) => {
    
    return(
        <>
            <Header signIn={signIn} user={user} selectedLanguage={selectedLanguage} />
        <div className="">
        <div className="flex grid grid-cols-1 py-36 sm:grid-cols-2">
                    <div className="px-8 lg:px-24 2xl:px-44">
                        <div className="">
                            <p className="text-blue-700 font-semibold text-xl"> {selectedLanguage === "es-ES" ? "Acerca de Nosotros" : "About us"} </p>
                            <p className="text-blue-800 font-semibold text-3xl pt-5"> {selectedLanguage === "es-ES" ? "Nuestra Misión" : "Our Mission"} </p>
                        </div>
                        <div className="flex justify-center">
                            <div className="pt-10 pb-32">
                                <p className="text-gray-500 text-justify font-medium pt-3"> {selectedLanguage === "es-ES" ? "Más del 90% del contenido académico se publica en inglés. Ante esto, buscamos hacer que este contenido sea accesible para cualquier persona, independientemente, del idioma que hable. " : "Over 90% of academic content is published in English. In light of this, we aim to make this content accessible to anyone, regardless of the language they speak."} <br /><br /> {selectedLanguage === "es-ES" ? "En 2022 lanzamos Konlap, el cual fue reemplazado a inicios del 2024 por VortiX AI, un motor de búsqueda que usa una combinación de keyword search, búsqueda semántica, búsqueda multiligue, etc. para ofrecer una nueva forma de encontrar contenido académico." : "In 2022, we launched Konlap, which was replaced in early 2024 by VortiX AI, a search engine that utilizes a combination of keyword search, semantic search, multilingual search, etc., to provide a new way of discovering academic content."} </p>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center items-center">
                                            <img src="https://luya.blob.core.windows.net/edux/last_bg_trans.png" className="w-2/3 h-auto" />
                    </div>
        </div>
        </div>
            <Footer selectedLanguage={selectedLanguage} />
        </>
    )
}

export default Search;