import { useState, useEffect } from "react"
import { Link } from "react-router-dom";
import { InformationCircleIcon } from "@heroicons/react/solid";

const Header = ({user, signIn}) => {


    const [selectedLanguage, setS] = useState("")

    useEffect(() => {
        const savedLanguage = localStorage.getItem('selectedLanguage');
        setS(savedLanguage)
    }, []);

    return (
        <div className="w-full flex">
            <Link to="/" className="flex items-center py-8 w-full md:w-1/3 xl:w-2/5 justify-center">
                <div className="flex items-center">
                    <p className="text-lg mr-3 cursor-pointer font-semibold text-blue-600">Vortex</p>
                    <button className="text-white flex items-center text-sm px-5 py-2 rounded-xl bg-blue-600">
                        Experimental <InformationCircleIcon className="w-4 h-4 ml-2" />
                    </button>
                </div>
            </Link>
            <div className="md:w-2/3 xl:w-3/5 hidden sm:flex justify-center">
                <div className="flex my-8 sm:w-3/5 justify-evenly">
                    <Link to="/research" className="font-semibold cursor-pointer text-blue-600 hover:text-blue-800">
                        {selectedLanguage === "es-ES" ? "Investigación" : "Research"}
                    </Link>
                    <Link to="/search" className="font-semibold cursor-pointer text-blue-600 hover:text-blue-800">
                        {selectedLanguage === "es-ES" ? "Acerca de" : "About"}
                    </Link>
                    <Link to="/chat" className="font-semibold cursor-pointer text-blue-600 hover:text-blue-800">
                        Chat
                    </Link>
                </div>
            </div>
        </div>

    )
}

export default Header;