import { useState, useEffect } from "react"
import Header from "./header";
import Footer from "./footer";
import {Link} from "react-router-dom"
import { ArrowCircleRightIcon } from "@heroicons/react/outline";

const Research = ({posts, loadingPost, selectedLanguage}) => {
    
    const sortedPublications = [...(posts ?? [])].sort((a, b) => b.timestamp - a.timestamp);

    const [postCount, setPostCount] = useState(3)

    const showMore = () => {
        setPostCount(postCount + 3)
    }

    const [buttons, setButtons] = useState(true)

    useEffect(() => {
        if (postCount > posts.length) {
            setButtons(false);
        } else {
            setButtons(true);
        }
    }, [postCount, posts.length]);

    return (
        <>
        <Header/>
            <div className="flex justify-center">
                <div className="w-11/12 grid-cols-1 grid grid-cols-1 sm:grid-cols-2">
                    <div className="flex py-20 items-center justify-center">
                        <img src="https://luya.blob.core.windows.net/edux/people.png" className="w-3/4 h-auto" />
                    </div>
                    <div className="pb-12 sm:py-44 pl-4 pr-4 sm:pl-32 sm:pr-20">
                        <p className="text-gray-500 font-medium py-3">{selectedLanguage === "es-ES" ? "IA en VortiX" : "AI at VortiX"}</p>
                        <p className="text-4xl font-medium text-gray-950 pt-5 pb-6">{selectedLanguage === "es-ES" ? "Haciendo la información accesible para todos" : "Making information accessible to everyone"}</p>
                        <p className="text-gray-500 text-justify font-medium py-8"> {selectedLanguage === "es-ES" ? "Estámos entrenando modelos para preservar, difundir, idiomas nativos de todo el mundo. Tú también puedes colaborar mediante el proyecto Babel, desarrollado en VortiX." : "We are training models to preserve and spread native languages from all over the world. You can also collaborate through the Babel project, developed at VortiX."} </p>
                        <a target="_blank" href="https://babelproject.io/" className="flex underline text-gray-500 cursor-pointer">{selectedLanguage === "es-ES" ? "Ir al Proyecto Babel" : "Go to Babel Project"} </a>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Research;