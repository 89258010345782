import React from "react";

const SelectRange = ({ onClose, start, setStart, end, setEnd, selectedLanguage }) => {
    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="bg-white p-8 rounded-lg shadow-lg">
                <h2 className="text-xl mt-4 text-gray-600 mb-8 font-semibold">
                    {selectedLanguage === "es-ES" ? "Rango de lectura " : "Reading range"}
                </h2>
                <form>
                    <div className="mb-4">
                        <label htmlFor="start" className="block text-gray-600 font-semibold mb-1">
                            {selectedLanguage === "es-ES" ? "Página de inicio:" : "Start page:"}
                        </label>
                        <input
                            type="number"
                            id="start"
                            value={start}
                            onChange={(e) => setStart(e.target.value)}
                            className="w-full outline-none px-2 py-2 border border-gray-300 rounded"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="end" className="block text-gray-600 font-semibold mb-1">
                            {selectedLanguage === "es-ES" ? "Página final:" : "End page:"}
                        </label>
                        <input
                            type="number"
                            id="end"
                            value={end}
                            onChange={(e) => setEnd(e.target.value)}
                            className="w-full outline-none px-2 py-2 border border-gray-300 rounded"
                        />
                    </div>
                    <div className="flex justify-center">
                        <button
                            type="submit"
                            className="px-4 py-2 font-semibold hover:bg-green-600 text-white bg-green-500 rounded"
                        >
                            {selectedLanguage === "es-ES" ? "Enviar" : "Submit"}
                        </button>
                    </div>
                    <div className="flex justify-end pt-8">
                        <button
                            type="button"
                            onClick={onClose}
                            className="px-4 py-2 mr-2 font-semibold text-red-500 hover:text-red-600 border-2 border-red-500 rounded"
                        >
                            {selectedLanguage === "es-ES" ? "Cerrar" : "Close"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SelectRange;