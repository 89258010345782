import {useState, useEffect} from "react"
import Papers from './paper';
import { query, where } from 'firebase/firestore';
import Chat from "./components/chat";
import "./App.css"
import List from "./components/list";
import Guide from "./components/guide";
import PrivacyPolicy from "./components/policy"
import Blog from "./components/blog"
import ScrollToTop from "./scroll"
import Selectlan from "./select";
import Post from "./components/post";
import { collection, getDocs } from 'firebase/firestore';
import { db } from "./firebase";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Search from "./components/search";
import Research from "./components/research";
import { auth, googleProvider } from "./firebase"
import Saved from "./components/saved";
import { useAuthState } from 'react-firebase-hooks/auth';
import { signInWithPopup } from 'firebase/auth';


function App() {

  const [user] = useAuthState(auth);

  const [posts, setPosts] = useState([]);
  const [loadingPosts, setLoadingPosts] = useState(false)
  const postsRef = collection(db, "posts");
  const userRef = collection(db, "custom_user");

  useEffect(() => {
   const getPosts = async () => {
     setLoadingPosts(true)
     const data = await getDocs(postsRef);
     setPosts(data?.docs?.map((doc) => ({ ...doc.data(), id: doc.id })));
     console.log(posts);
     console.log("Listo")
     setLoadingPosts(false)
   }

   getPosts();
   console.log(posts);
  }, [])

  const [userData, setUserData] = useState()
  const [userName, setUserName] = useState("")
  const [userAge, setUserAge] = useState("")

  useEffect(() => {
    if (user) {
      const getUserdata = async () => {
        const data = await getDocs(userRef);
        setUserData(data?.docs?.map((doc) => ({ ...doc?.data(), id: doc?.id })));
        if (userData) {
          setUserName(userData[0]?.name)
          setUserAge(userData[0]?.age)
        }
        else {

        }
      }

      getUserdata();
      console.log(userData);
    }
  }, [])

  const signIn = async (e) => {
    e.preventDefault();
    try {
      const result = await signInWithPopup(auth, googleProvider);
      console.log("User email signed in:", result)
    } catch (error) {
      console.error("Sign-in error:", error);
    }
  };

  const [selectedLanguage, setSelectedLanguage] = useState('');
  
  useEffect(() => {
    const savedLanguage = localStorage.getItem('selectedLanguage');
    if (savedLanguage) {
      setSelectedLanguage(savedLanguage);
    } else {
      setShowModal(true);
    }
  }, []);
  const [showModal, setShowModal] = useState(false);

  const handleSelectLanguage = (languageCode) => {
    setSelectedLanguage(languageCode);
    localStorage.setItem('selectedLanguage', languageCode);
    setShowModal(false);
  };

  const [isAuthenticated, setIsAuthenticated] = useState(true);

  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    if (!isAuthenticated) {
      setIsOpen(!isOpen);
    }
  };

  const [publications, setPublications] = useState([]);
  const [savedPapers, setSavedPapers] = useState([]);
  const paperRef = collection(db, "s3");
  const [loadingS3, setLoadingS3] = useState(false)

  useEffect(() => {

    const getPapers = async () => {
      if (user) {
        setLoadingS3(true);
        const q = query(paperRef, where("uid", "==", user.uid));
        const data = await getDocs(q);
        setSavedPapers(data?.docs?.map((doc) => ({ ...doc.data(), id: doc.id })));
        console.log(savedPapers);
        console.log(savedPapers);
        setLoadingS3(false);
      }
    };

    if (user) {
      getPapers();
    }
  }, [user]); 
  

  useEffect(() => {
    document.title = "VortiX - AI Search Engine for Scientific Research"
  }, [])


  useEffect(() => {
    (function (c, l, a, r, i, t, y) {
      c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
      t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
      y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
    })(window, document, "clarity", "script", "ksl0ws77dq");
  }, []);

  return (
    <Router>
      <Selectlan handleSelectLanguage={handleSelectLanguage} selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} showModal={showModal} setShowModal={setShowModal} />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Papers savedDocs={savedPapers} userName={userName} userAge={userAge} signIn={signIn} user={user} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} toggleModal={toggleModal} handleSelectLanguage={handleSelectLanguage} selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} showModal={showModal} setShowModal={setShowModal} />} />
        <Route path="/blog" element={<Blog posts={posts} selectedLanguage={selectedLanguage} setPosts={setPosts} />} />
        <Route path="/privacy" element={<PrivacyPolicy selectedLanguage={selectedLanguage} />} />
        <Route path="/guide" element={<Guide selectedLanguage={selectedLanguage} />} />
        <Route path="/search" element={<Search signIn={signIn} user={user} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} toggleModal={toggleModal} selectedLanguage={selectedLanguage} />} />
        <Route path="/chat" element={<Chat userName={userName} userAge={userAge} signIn={signIn} user={user} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} toggleModal={toggleModal} selectedLanguage={selectedLanguage} />} />
        <Route path="/research" element={<Research posts={posts} loadingPosts={loadingPosts} user={user} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} toggleModal={toggleModal} selectedLanguage={selectedLanguage} />} />
        <Route path="/saved" element={<Saved user={user} savedPapers={savedPapers} publications={publications} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} toggleModal={toggleModal} selectedLanguage={selectedLanguage} />} />
        <Route path="/post/:id" element={<Post posts={posts} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} toggleModal={toggleModal} selectedLanguage={selectedLanguage} />} />
      </Routes>
    </Router>
  );
}

export default App;
