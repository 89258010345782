import React, { useState, useEffect, useRef } from 'react';
import pdfOne from "./assets/icons/pdfOne.png"
import saveOne from "./assets/icons/saveOne.png"
import List from './components/list';
import quoteOne from "./assets/icons/quoteOne.png"
import { Link } from "react-router-dom"
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import { BeakerIcon, ChatAltIcon } from '@heroicons/react/solid';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import checkOne from "./assets/icons/checkOne.png"
import { LuPlusCircle } from "react-icons/lu";
import checkgreenOne from "./assets/icons/checkGreenOne.png"
import { FaHome, FaCompass, FaTools, FaEnvelope } from "react-icons/fa"
import sparkOne from "./assets/icons/sparkOne.png"
import { HiChatBubbleBottomCenterText, HiMiniArrowTopRightOnSquare } from "react-icons/hi2";
import { HiOutlineFilter, HiPaperAirplane } from "react-icons/hi";
import { FaArrowCircleLeft, FaRobot, FaRegCopy, FaHeart } from "react-icons/fa";
import { LuSparkles } from "react-icons/lu";
import { BiWorld } from "react-icons/bi";
import axios from "axios";
import UserInfoPopup from "./components/userinfo"
import fine from "./assets/icons/fine_tune.png"
import translateimage from "./assets/icons/g_translate.png"
import chatOne from "./assets/icons/travel_explore.png"
import { FaPaperPlane } from "react-icons/fa";
import { FaPlusCircle, FaSpinner, FaTimesCircle } from 'react-icons/fa';
import Header from "./components/header"
import Footer from './components/footer';
import ScrollToTop from "./scroll"
import { FaCommentAlt } from 'react-icons/fa';
import { RiArrowDownSLine, RiArrowRightDownLine, RiArrowRightLine, RiArrowRightSLine, RiFilePdf2Fill } from "react-icons/ri"
import { IoArrowBackCircleOutline, IoSearchCircleOutline, IoSparklesSharp } from 'react-icons/io5';
import { PiArrowCircleLeft, PiBookBookmarkBold, PiBookmarksSimpleFill, PiBooksBold, PiChatsTeardrop, PiCheckCircleBold, PiCircleBold, PiCopyBold, PiPaperPlaneBold, PiPaperPlaneRightBold, PiPlusCircleBold, PiQuotesBold, PiSparkleBold, PiXCircleBold } from 'react-icons/pi';
import { FaChartBar, FaChevronDown, FaFilePdf, FaLanguage, FaRegFilePdf, FaSearch } from 'react-icons/fa';
import { auth, googleProvider } from "./firebase";
import { ImQuotesLeft } from "react-icons/im";
import { getFirestore, collection, getDocs, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from "./firebase"
import { useParams, useNavigate } from "react-router-dom";
import { CheckCircleIcon, QuestionMarkCircleIcon, XCircleIcon, NewspaperIcon, AcademicCapIcon, SparklesIcon, BookmarkIcon, UserCircleIcon, MenuAlt3Icon, ShareIcon, AdjustmentsIcon, InformationCircleIcon, ArrowDownIcon, XIcon, ClipboardIcon, CheckIcon, DocumentIcon } from "@heroicons/react/solid";
import Home from './home';
import { CopyIcon, DatabaseIcon, SaveIcon, SearchIcon, SparkleIcon, Sparkles, UploadCloudIcon, UploadIcon } from 'lucide-react';
import { MdOutlineFilterList } from 'react-icons/md';
import { VscFeedback } from 'react-icons/vsc';
import { FiDownload } from 'react-icons/fi';

const topJournals = [
    "Nature",
    "The New England Journal of Medicine",
    "Science",
    "Conference on Computer Vision and Pattern Recognition",
    "The Lancet",
    "Nature Communications",
    "Advanced Materials",
    "Cell",
    "Neural Information Processing Systems",
    "International Conference on Learning Representations",
    "JAMA",
    "Science of The Total Environment",
    "Nature Medicine",
    "Proceedings of the National Academy of Sciences",
    "Angewandte Chemie International Edition",
    "Chemical Reviews",
    "International Conference on Machine Learning",
    "Chemical Society Reviews",
    "Journal of Cleaner Production",
    "Nucleic Acids Research",
    "European Conference on Computer Vision",
    "Advanced Energy Materials",
    "Journal of the American Chemical Society",
    "IEEE Access",
    "Advanced Functional Materials",
    "Renewable and Sustainable Energy Reviews",
    "ACS Nano",
    "BMJ",
    "Physical Review Letters",
    "International Journal of Molecular Sciences",
    "Journal of Clinical Oncology",
    "AAAI Conference on Artificial Intelligence",
    "Science Advances",
    "PLoS ONE",
    "Frontiers in Immunology",
    "Scientific Reports",
    "Circulation",
    "Chemical Engineering Journal",
    "Energy & Environmental Science",
    "Applied Catalysis B: Environmental",
    "International Journal of Environmental Research and Public Health",
    "The Lancet Oncology",
    "Journal of the American College of Cardiology",
    "Meeting of the Association for Computational Linguistics",
    "Nutrients",
    "Nature Genetics",
    "Morbidity and Mortality Weekly Report",
    "Applied Energy",
    "Nature Biotechnology",
    "Sustainability",
    "Nano Energy",
    "Joule",
    "Journal of Materials Chemistry A",
    "Nature Materials",
    "IEEE Transactions on Pattern Analysis and Machine Intelligence",
    "ACS applied materials & interfaces",
    "Nature Energy",
    "ACS Catalysis",
    "The Lancet Infectious Diseases",
    "Conference on Empirical Methods in Natural Language Processing",
    "Journal of Business Research",
    "Gastroenterology",
    "European Heart Journal",
    "IEEE Internet of Things Journal",
    "Nature Nanotechnology",
    "Environmental Pollution",
    "The Astrophysical Journal",
    "Environmental Science & Technology",
    "Frontiers in Psychology",
    "Immunity",
    "Sensors",
    "Annals of Oncology",
    "ACS Energy Letters",
    "Journal of Hazardous Materials",
    "Communications Surveys & Tutorials",
    "Nature Neuroscience",
    "Gut",
    "Molecular Cancer",
    "Molecules",
    "Small",
    "Clinical Infectious Diseases",
    "Nature Methods",
    "Accounts of Chemical Research",
    "IEEE Transactions on Industrial Informatics",
    "Physical Review D",
    "Bioresource Technology",
    "American Economic Review",
    "Cell Metabolism",
    "Monthly Notices of the Royal Astronomical Society",
    "Chemosphere",
    "Blood",
    "Cell Reports",
    "Nano Letters",
    "Advanced Science",
    "Journal of High Energy Physics",
    "Nature Reviews Immunology",
    "Technological Forecasting and Social Change",
    "Frontiers in Microbiology",
    "Water Research",
]

const Papers = ({ handleSelectLanguage, selectedLanguage, savedDocs, setSelectedLanguage, showModal, setShowModal, user, userName, userAge, signIn }) => {
    const [questionx, setQuestionx] = useState("");
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    };

    const [firstSearch, setFirstSearch] = useState(false)


    const [chatDoc, setChatDoc] = useState(false)
    const [followup, setFollowup] = useState(false)
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [selectedPaper, setSelectedPaper] = useState(null)
    const [showDetails, setShowDetails] = useState(false);
    const [mainWebsite, setMainWebsite] = useState(true)
    const [comparePapers, setComparePapers] = useState(false)

    const [indChat, setIndChat] = useState(true)

    const handleDocumentSelection = (paper) => {
        scrollToTop()
        setSelectedDocument(paper);
        setShowDetails(true);
        setChatDoc(false)
        setComparePapers(false)
        setMainWebsite(false)
        setFollowup(false)
    };

    const individualChat = () => {
        setComparePapers(false)
        setIndChat(true)
    }

    const showComparePapers = () => {
        setIndChat(false)
        setComparePapers(true)
    }

    const [savePaper, setSavePaper] = useState(false)

    const closeDetails = () => {
        setShowDetails(false);
        setMainWebsite(true)
        setFollowup(false)
        setChatDoc(false)
        setComparePapers(false)
        setSavePaper(false)
    };

    const handlePaperSelection = (e, paper) => {
        console.log("Paper details", paper?.openAccessPdf?.url || paper?.url)
        scrollToTop()
        setSelectedPaper(paper)
        setChatDoc(true)
        setComparePapers(false)
        setShowDetails(false)
        setFollowup(false)
        setMainWebsite(false)
        downloadPDF(e, paper)
    }

    const continueChat = () => {
        Navigate("/chat")
    }

    const closecontinueChat = () => {
        scrollToTop()
        setChatDoc(false)
        setComparePapers(false)
        setShowDetails(false)
        setFollowup(false)
        setMainWebsite(true)
    }

    const [searchTerm, setSearchTerm] = useState('')
    const [loading, setLoading] = useState(false);
    const [recommendations, setRecommendations] = useState([]);
    const [isOpen, setIsOpen] = useState(false);

    const [trOpen, setTrOpen] = useState(false)

    const clear = () => {
        setSearchTerm("")
    }


    const handleLogout = () => {
        auth.signOut();
    };

    const [fieldsOfStudy, setFieldsOfStudy] = useState([]);
    const [publicationTypes, setPublicationTypes] = useState([]);
    const handleCheckboxChange = (event, type) => {
        const { checked, value } = event.target;
        if (type === 'field') {
            setFieldsOfStudy(prev =>
                checked ? [...prev, value] : prev.filter(item => item !== value)
            );
        } else if (type === 'publication') {
            setPublicationTypes(prev =>
                checked ? [...prev, value] : prev.filter(item => item !== value)
            );
        }
    };

    const Navigate = useNavigate()

    const [enabled, setEnabled] = useState(() => {
        const saved = localStorage.getItem('enabledState');
        return saved === null ? true : saved === 'true';
    });

    useEffect(() => {
        localStorage.setItem('enabledState', enabled);
    }, [enabled]);


    const [free, setFree] = useState("")

    useEffect(() => {
    }, [fieldsOfStudy]);

    useEffect(() => {
    }, [publicationTypes]);

    const queriesRef = collection(db, "queries");

    const trackear = async (e) => {

        try {
            await addDoc(queriesRef, {
                query: searchTerm,
            });
        }
        catch {

        }
    }

    const [year, setYear] = useState("")
    const [yearRange, setYearRange] = useState("")
    const [yearBefore, setYearBefore] = useState("")
    const [yearAfter, setYearAfter] = useState("")
    const [apiAnswer2, setApiAnswer2] = useState("")
    const [context, setContext] = useState("")
    const [webResults, setWebResults] = useState(false);


    const [data, setData] = useState({
        summary: '',
        yes: '',
        maybe: '',
        no: '',
        answer: "",
        responses: []
    });

    const countPapersByRelation = (papers) => {
        const counts = {
            yes: 0,
            maybe: 0,
            no: 0,
        };

        papers?.forEach((paper) => {
            if (paper?.abstractRelation === 'entailment') {
                counts.yes++;
            } else if (paper?.abstractRelation === 'neutral') {
                counts.maybe++;
            } else if (paper?.abstractRelation === 'contradiction') {
                counts.no++;
            }
        });

        return counts;
    };
    const [summaryx, setSummaryX] = useState("")
    const [loadingAzureSearch, setLoadingAzureSearch] = useState(false)
    const [roberta, setRoberta] = useState(false)
    const [fuentes, setFuentes] = useState([])
    const [arrow, setArrow] = useState(false)
    const [receiving, setReceiving] = useState(false)

    const handleSearch = async (e, overrideSearchTerm = null) => {
        setArrow(false);
        setFirstSearch(true);
        setRoberta(false);
        const searchTermx = overrideSearchTerm || searchTerm;
        console.log("Buscando: ", searchTermx);
        setSummaryX("");
        const fieldsOfStudyParam = encodeURIComponent(fieldsOfStudy.join(','));
        setWebResults(false);
        const publicationTypesParam = encodeURIComponent(publicationTypes.join(','));
        if (e) e.preventDefault();
        setLoading(true);
        setIsOpen(false);
        setData({ yes: '', maybe: '', no: '', answer: "", responses: [] });
        setTrOpen(false);
        const encodedSearchTerm = encodeURIComponent(searchTermx);
        trackear();

        const searchUrl = `https://athena.gaia-ml.com/api/search?searchTerm=${encodedSearchTerm}&selectedLanguage=${selectedLanguage}&year=${year}&yearRange=${yearRange}&yearBefore=${yearBefore}&yearAfter=${yearAfter}&free=${free}&fieldsOfStudyParam=${fieldsOfStudyParam}&publicationTypesParam=${publicationTypesParam}`;
        
        if (searchTermx.split(" ").length > 2) {
            const embedUrl = `https://athena.gaia-ml.com/api/embed?searchTerm=${encodedSearchTerm}&selectedLanguage=${selectedLanguage}`;

            const eventSource = new EventSource(embedUrl);

            let lastNonFinalMessageData = null;

            eventSource.onmessage = (event) => {
                const data = JSON.parse(event.data);
                setLoadingAzureSearch(true);
                setReceiving(true)
                if (data.citations) {
                    const entailmentCount = data?.citations?.filter(source => source?.abstractRelation === "entailment")?.length;
                    const neutralCount = data?.citations.filter(source => source?.abstractRelation === "neutral")?.length;
                    const contradictionCount = data?.citations?.filter(source => source?.abstractRelation === "contradiction")?.length;

                    lastNonFinalMessageData = {
                        sources: data?.citations,
                        roberta_large_analysis: {
                            entailment: entailmentCount,
                            neutral: neutralCount,
                            contradiction: contradictionCount
                        }
                    };
                    setData(prevData => ({
                        ...prevData,
                        yes: entailmentCount,
                        maybe: neutralCount,
                        no: contradictionCount
                    }));
                }

                if (data.streamingResponse) {
                    setRoberta(true);
                    setLoadingAzureSearch(false);
                    let rawData = data.streamingResponse;
                    rawData = rawData.replace(/^data:\s*/, '').trim();

                    if (rawData === '[DONE]') {
                        setArrow(true);
                        eventSource.close();
                        callSearchEndpoint();
                        setReceiving(false)
                        return;
                    }
                    const streamingData = JSON.parse(rawData);

                    if (streamingData.choices && streamingData.choices[0].delta && streamingData.choices[0].delta.context && streamingData.choices[0].delta.context.messages && streamingData.choices[0].delta.context.messages[0].content) {
                        const content = JSON.parse(streamingData.choices[0].delta.context.messages[0].content);
                        const citations = content.citations;
                        setFuentes(citations);
                    }

                    if (streamingData.choices && streamingData.choices[0].delta && streamingData.choices[0].delta.content) {
                        setSummaryX((prevSummary) => prevSummary + streamingData.choices[0].delta.content);
                    }
                }
            };

            eventSource.onerror = (error) => {
                setReceiving(false)
                console.error('Error fetching embed data:', error);
                eventSource.close();
                setLoadingAzureSearch(false);
            };

            eventSource.onopen = () => {
                setReceiving(true)
                console.log('EventSource opened');
                setLoadingAzureSearch(true);
            };

            eventSource.onclose = () => {
                setReceiving(false)
                console.log('EventSource closed');
            };

            setTimeout(() => {
                callSearchEndpoint();
            }, 1000);
        } else {
            callSearchEndpoint();
        }

        async function callSearchEndpoint() {
            try {
                const searchResponse = await fetch(searchUrl);
                const searchData = await searchResponse.json();
                if (searchData.untranslatedPapers) {
                    console.log(searchData.untranslatedPapers);
                    setRecommendations(searchData.untranslatedPapers);
                    const paperCounts = countPapersByRelation(searchData.untranslatedPapers);
                    setContext(searchData.untranslatedPapers.slice(0, 1));
                    const hasWebResults = searchData.untranslatedPapers.some(paper => paper.type === "website");
                    setWebResults(hasWebResults);
                    setLoading(false)
                }
            } catch (error) {
                console.error('Error fetching search data:', error);
                setLoading(false)
            }
        }
    };

    const [authorData, setAuthorData] = useState()

    const searchAuthor = async (authorId) => {
        try {
            const answer = ("https://athena.gaia-ml.com/api/searchAuthor", {
                params: { authorId }
            })
            setAuthorData(answer)
            console.log(answer)
        }
        catch (error) {
            console.log(error)
        }
    }

    const trBar = useRef()

    const [apiMessages, setApiMessages] = useState([]);
    const [userQuestion, setUserQuestion] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const mainChat = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const response = await axios.get("https://vortex-backend-wyg2.onrender.com/mainchat", {
                params: { userQuestion, userName, userAge, context },
            });

            setApiMessages((prevMessages) => [
                ...prevMessages,
                { role: "user", content: userQuestion },
                { role: "assistant", content: response.data.analysis },
            ]);

            setUserQuestion("");
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    };

    const handleClicko = (event) => {
        if (trBar.current && !trBar.current.contains(event.target)) {
            setTrOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClicko);
        return () => {
            document.removeEventListener('mousedown', handleClicko);
        };
    }, []);


    const sidebarRef = useRef();

    const handleClickOutside = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const [selectedSourceIndex, setSelectedSourceIndex] = useState(null);
    const searchRef = collection(db, "searches");

    const [messages, setMessages] = useState([]);

    const fileInputRef = useRef(null);

    const [uploadDoc, setUploadDoc] = useState(false)

    const handleFileChange = async (e, paper) => {
        const files = e.target.files;
        const allowedTypes = ['application/json', 'text/plain', 'text/csv', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'];

        if (files.length > 0) {
            const formData = new FormData();

            Array.from(files).forEach(file => {
                if (allowedTypes.includes(file.type)) {
                    formData.append('files', file);
                } else {
                    alert(selectedLanguage === "es-ES" ? `El tipo de archivo ${file.type} no está permitido` : `File type ${file.type} is not allowed.`);
                }
            });

            if (formData.has('files')) {
                formData.append('paperurl', paper?.openAccessPdf?.url);

                try {
                    setUploadDoc(true);
                    const response = await axios.post("https://vortex-backend-wyg2.onrender.com/upload", formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    });
                    console.log(response.data);
                } catch (error) {
                    console.error(error);
                } finally {
                    setUploadDoc(false);
                }
            } else {
                alert(selectedLanguage === "es-ES" ? "Ningún archivo, de los tipos permitidos, fue seleccionado" : "No files of the allowed types were selected.");
            }
        }
    };

    const [getPDF, setGetPDF] = useState(false)

    const [displayError, setDisplayError] = useState(false)

    const [ready, setReady] = useState(false)

    const downloadPDF = async (e, paper) => {
        setGetPDF(true)
        const folderName = user?.email
        if (e) e.preventDefault();
        const url = paper?.openAccessPdf?.url || paper?.url;
        const type = paper?.type;
        console.log("Se descargará: ", url)
        try {
            const response = await axios.post("https://vortex-backend-wyg2.onrender.com/download", { url, type, folderName });
            console.log(response);
            setGetPDF(false)
            setReady(true)
        } catch (err) {
            setDisplayError(true)
            console.error(err);
        } finally {
        }
    };

    const [loadRequest, setLoadRequest] = useState(false)

    const [savedPapers, setSavedPapers] = useState(null);

    const [saved, setSaved] = useState(false)

    const handleSaving = (paper) => {
        setSaved(true)
        setSavedPapers(paper)
        saveToFirebase(paper)
    };

    const saveRef = collection(db, "s3");

    const saveToFirebase = async (paper) => {
        if (!user || !user.email) {
            signIn()
            return;
        }
        try {
            await addDoc(saveRef, {
                ...paper,
                id: paper?.paperId,
                uid: user.uid,
                timestamp: serverTimestamp()
            });
            setSavePaper(true)
        } catch (error) {
            alert(selectedLanguage === "es-ES" ? "Error al guardar" : "Error saving");
        }
    };

    const [showCopiedMessage, setShowCopiedMessage] = useState(false);
    const [citationPopup, setCitationPopup] = useState({ show: false, citation: '' });

    const formatCitationAPA = (selectedDocument) => {
        const authors = selectedDocument?.author?.name || selectedDocument?.author || "";
        const year = selectedDocument?.year || "";
        const title = selectedDocument?.title || "";
        const journal = selectedDocument?.journal?.name || "";
        const volume = selectedDocument?.volume || "";
        const pages = selectedDocument?.pages || "";
        const url = selectedDocument?.openAccessPdf?.url || selectedDocument?.url;
        return `${authors} (${year}). ${title}. <i>${journal}</i>, ${volume}, ${pages} <br/> <p style="color: #2563eb;">${url}</p>`;
    };

    const showCitationPopup = (selectedDocument) => {
        const citation = formatCitationAPA(selectedDocument);
        setCitationPopup({ show: true, citation });
    };

    const closeCitationPopup = () => {
        setCitationPopup({ show: false, citation: '' });
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        setShowCopiedMessage(true);
        setTimeout(() => {
            setShowCopiedMessage(false);
        }, 1500);
    };

    const [files, setFiles] = useState([]);

    const handleFileChangePapers = (e) => {
        const selectedFiles = Array.from(e.target.files);
        setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
    };

    const handleUpload = async () => {
        const name = user?.email;
        const formData = new FormData();

        files.forEach((file, index) => {
            const paperName = `${name}_${index + 1}`;
            formData.append('files', file);
            formData.append('fileNumber', index + 1);
            formData.append('name', paperName);
        });

        console.log('Form Data:', formData);
        console.log(name);

        try {
            await axios.post('https://vortex-backend-wyg2.onrender.com/uploadPapers', formData);
            alert('Papers uploaded successfully');
            setFiles([]);
        } catch (error) {
            console.error('Error uploading papers:', error);
            alert('Error uploading papers');
        }
    };

    const [singleQuestion, setSingleQuestion] = useState("")

    const [Topic, setTopic] = useState(false);
    const [Summary, setSummary] = useState(false);
    const [Findings, setFindings] = useState(false);
    const [Limitations, setLimitations] = useState(false);
    const [outcomeMeasured, setOutcomeMeasured] = useState(false);
    const [customValue, setCustomValue] = useState("");
    const [apiAnswer, setApiAnswer] = useState("");
    const [customFields, setCustomFields] = useState([]);

    const handleCustomFieldChange = (event) => {
        if (event.key !== 'Enter') {
            return;
        }
        if (customValue.trim() !== "") {
            setCustomFields([...customFields, customValue.trim()]);
            setCustomValue("");
        }
    };

    const removeCustomField = (field) => {
        setCustomFields(customFields.filter((f) => f !== field));
    };

    const instruction = selectedLanguage === "es-ES" ? `Extrae la siguiente información sobre el artículo: ${Topic ? "Tema principal, " : ""}${Limitations ? "Limitaciones, " : ""}${outcomeMeasured ? "Resultado medido, " : ""}${Summary ? "Resumen, " : ""}${Findings ? "Hallazgos, " : ""}${customFields.join(", ")}. Intenta lo mejor posible para devolver una respuesta incluso si el artículo no es académico o no contiene todos los campos, si es así, devuelve esos campos como cadenas vacías. Devuelve tu respuesta en el siguiente formato: data=["paper_1"={"topic":"metabolismo en humanos y ...","summary":"El estudio muestra que","main_findings":"..."}]. Siempre devuelve una respuesta con al menos algunos de los campos solicitados. El artículo no tiene que incluir párrafos específicos sobre el resumen, el tema, la metodología, etc. Debes generar estos campos resumiendo el artículo, analizando qué métodos siguieron los autores, qué limitaciones enfrentó o podría enfrentar el estudio, etc.` : `Extract the following information about the paper: ${Topic ? "Main Topic, " : ""}${Limitations ? "Limitations, " : ""}${outcomeMeasured ? "Outcome Measured, " : ""}${Summary ? "Summary, " : ""}${Findings ? "Findings, " : ""}${customFields.join(", ")}. Try your best to return an answer even if the papers is not academic or does not contain all the fields, if so return those fields as empty strings. Return your answer in the following format: data=["paper_1"={"topic":"metabolism in humans and ...","summary":"The study shows that","main_findings":"..."}]. Always return an answer with at least some of the fields asked. The paper do not have to include specific paragraphs about the summary, topic, methodoly, etc you should generate these fields by summarizing the paper, analyzing what methods the authors followed, what limitations the study faced or could face, etc..`

    const [retrievingCustomData, setRetrievingCustomData] = useState(false)

    const sendRequest = async (e, selectedPaper) => {
        e.preventDefault();
        const folderName = user?.email;
        const type = selectedPaper?.type;
        console.log("question is: ", questionx);
        setLoadRequest(true);
        setMessages(messages => [
            ...messages,
            { type: 'user', text: questionx },
            { type: 'api', text: 'loading' }
        ]);
        setQuestionx("");

        try {
            const response = await fetch("https://vortex-backend-wyg2.onrender.com/ask-paper", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    query: { type, question: questionx, userName, userAge, folderName },
                }),
            });

            const reader = response.body.getReader();
            const textDecoder = new TextDecoder('utf-8');
            let answer = '';

            async function readChunk() {
                const { value, done } = await reader.read();
                if (done) {
                    console.log('Streaming complete');
                    return;
                }
                const decodedChunk = textDecoder.decode(value, { stream: true });
                console.log('Received chunk:', decodedChunk);
                answer += decodedChunk;

                setMessages(messages => {
                    const newMessages = [...messages];
                    const lastMessage = newMessages[newMessages.length - 1];
                    if (lastMessage.type === 'api') {
                        lastMessage.text = answer;
                    } else {
                        newMessages.push({ type: 'api', text: answer });
                    }
                    return newMessages;
                });

                await readChunk();
            }

            await readChunk();
        } catch (error) {
            console.error(error);
        } finally {
            setLoadRequest(false);
            setQuestionx("");
        }
    };

    const sendRequest2 = async (e) => {
        e.preventDefault();
        const folderName = user?.email;
        setRetrievingCustomData(true);
        console.log(selectedLanguage);
        const question = instruction;
        setApiAnswer('');

        try {
            const response = await fetch("https://vortex-backend-wyg2.onrender.com/ask-paper", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    query: { question, folderName },
                }),
            });

            const reader = response.body.getReader();
            const textDecoder = new TextDecoder('utf-8');
            let answer = '';
            async function readChunk() {
                const { value, done } = await reader.read();
                if (done) {
                    console.log('Streaming complete');
                    return;
                }

                const decodedChunk = textDecoder.decode(value, { stream: true });
                console.log('Received chunk:', decodedChunk);
                answer += decodedChunk;
                setApiAnswer((prevAnswer) => prevAnswer + decodedChunk);

                await readChunk();
            }

            await readChunk();
        } catch (error) {
            console.error(error);
        } finally {
            setRetrievingCustomData(false);
        }
    };

    const getData = (apiAnswer) => {
        const regex = /"(\w+)"\s*:\s*"(.*?)"/g;
        const paperData = {};

        let match;
        while ((match = regex.exec(apiAnswer)) !== null) {
            const key = match[1];
            const value = match[2];
            paperData[key] = value;
        }

        return paperData;
    };

    const paperData = getData(apiAnswer);

    const [showPopup, setShowPopup] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const openPopup = () => {
        setShowPopup(true);
    };

    const closePopup = () => {
        setShowPopup(false);
    };
    const handleUserInfoSubmit = (info) => {
        setUserInfo(info);
    };

    const [links, setLinks] = useState("")

    const getLinks = async (requestLink) => {
        try {
            const response = await axios.get('https://athena.gaia-ml.com/api/google-scholar-search', {
                params: { query: requestLink },
            });
            setLinks(response.data.url);
            console.log("first: ", response.data.url)
            window.open(response.data.url, '_blank');
        } catch (error) {
            if (error.response) {
            } else {
            }
        }
    };

    const clearAll = () => {
        setFirstSearch(false)
        setSearchTerm("")
    }

    const closeChat = () => {
        setIndChat(true)
        setComparePapers(false)
        setChatDoc(false)
        setShowDetails(false)
        setComparePapers(false)
        setMainWebsite(true)
        setMessages([])
        setGetPDF(false)
        setReady(false)
        setDisplayError(false)
        setApiAnswer("")
    }

    const [view, setView] = useState(false)

    const viewSources = () => {
        setView(true)
    }

    const [copySuccess, setCopySuccess] = useState(false);

    const handleCopy = () => {
        if (typeof summaryx === 'string') {
            navigator.clipboard.writeText(summaryx.replace(/\[doc\d+\]/g, ''))
                .then(() => {
                    setCopySuccess(true);
                    setTimeout(() => setCopySuccess(false), 2000);
                })
                .catch(err => console.error('Failed to copy text: ', err));
        }
    };

    const downloadResultsAsCSV = (e) => {
        e.preventDefault();
        console.log("Download started");

        try {
            if (!Array.isArray(recommendations) || recommendations.length === 0) {
                console.error("recommendations is not a valid array or is empty");
                alert("No data available to download");
                return;
            }

            console.log("Number of recommendations:", recommendations.length);

            const csvData = recommendations.map(paper => {
                console.log("Processing paper:", paper);
                return {
                    Title: paper.translatedTitle || "N/A",
                    Abstract: paper.tldr?.text || paper.translatedAbstract || "N/A",
                    Journal: paper.journal?.name || "N/A",
                    Author: paper.author ? paper.author.split(',')[0] + " et al" : 'N/A',
                    CitationCount: paper.citationCount || "N/A",
                    Year: paper.year || "N/A",
                    OpenAccessURL: paper.openAccessPdf?.url || "N/A"
                };
            });

            console.log("CSV data prepared:", csvData);

            if (summaryx) {
                csvData.push({
                    Title: "AI-Generated Answer",
                    Abstract: summaryx,
                    Journal: "",
                    Author: "",
                    CitationCount: "",
                    Year: "",
                    OpenAccessURL: ""
                });
            }

            const csv = Papa.unparse(csvData);
            console.log("CSV generated");

            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            const filename = `search_results_${new Date().toISOString().replace(/[:.]/g, "-")}.csv`;

            saveAs(blob, filename);
            console.log("File save triggered");
        } catch (error) {
            console.error("Error in downloadResultsAsCSV:", error);
            alert("An error occurred while downloading the CSV. Please check the console for details.");
        }
    };

    const [additionalData, setAdditionalData] = useState(null);
    const [results, setResults] = useState([]);
    const [rs, setRs] = useState(false)
    const [dg, setDg] = useState(false)

    const retrieveDatasets = async (e) => {
        e.preventDefault();
        setRs(true)
        try {
            const response = await axios.get("https://vortex-backend-wyg2.onrender.com/datasets", {
                params: { query: searchTerm }
            });
            setResults(response.data);
            setRs(false)
        } catch (error) {
            console.error('An error occurred:', error);
            setRs(false)
        }
    }

    const retrieveData = async (e, result) => {
        e.preventDefault();
        setDg(true)
        try {
            const response = await fetch('https://vortex-backend-wyg2.onrender.com/single-search-api', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name: result.name, code: result.code, searchTerm: searchTerm }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const resultData = await response.json();
            setAdditionalData(resultData.data[1]);
            console.log(resultData);
            setDg(false)
        } catch (error) {
            console.error('Error fetching additional data:', error);
            setDg(false)
        }
    };



    return (
        <>
            {!firstSearch && <Home handleLogout={handleLogout} handleSearch={handleSearch} searchTerm={searchTerm} setSearchTerm={setSearchTerm} signIn={signIn} user={user} handleSelectLanguage={handleSelectLanguage} selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} showModal={showModal} setShowModal={setShowModal} />}
            {showPopup && (
                <UserInfoPopup signIn={signIn} user={user} selectedLanguage={selectedLanguage} onClose={closePopup} onSubmit={handleUserInfoSubmit} />
            )}
            {firstSearch &&
                <>
                    <ScrollToTop />
                    {showDetails && selectedDocument && (
                        <>
                            {citationPopup.show && (
                                <div className="fixed inset-0 flex items-center justify-center z-50">
                                    <div className="modal-overlay absolute w-full h-full bg-gray-900 bg-opacity-50"></div>
                                    <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto shadow-xl z-50">
                                        <div className="">
                                            <div className="w-full flex bg-gray-100">
                                                <div className="w-1/6"></div>
                                                <div className="w-4/6 flex justify-center py-3">
                                                    <p className="text-lg font-semibold text-gray-700">
                                                        {selectedLanguage === "es-ES" ? "Citar fuente" : "Cite source"}
                                                    </p>
                                                </div>
                                                <div className="w-1/6 flex items-center justify-center">
                                                    <XCircleIcon
                                                        onClick={closeCitationPopup}
                                                        className="text-gray-700 h-6 w-6 hover:text-gray-800 cursor-pointer"
                                                    />
                                                </div>
                                            </div>
                                            <div className="text-gray-600 rounded bg-gray-100 text-sm light p-6 relative">
                                                <div
                                                    className="citation-text font-sans break-words"
                                                    dangerouslySetInnerHTML={{
                                                        __html: citationPopup.citation
                                                    }}
                                                ></div>
                                                <PiCopyBold
                                                    className="my-2 w-5 h-5 cursor-pointer hover:text-gray-800 text-gray-600"
                                                    onClick={() =>
                                                        copyToClipboard(
                                                            citationPopup[`citation${citationPopup.selectedTab}`]
                                                        )
                                                    }
                                                    title="Copy to clipboard"
                                                />
                                                {showCopiedMessage && (
                                                    <div className="absolute bottom-2 border rounded-full left-1/2 transform -translate-x-1/2 bg-white border-green-500 text-green-500 px-5 py-2 rounded">
                                                        {selectedLanguage === "es-ES" ? "Texto copiado" : "Text copied"}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <Header />
                            <div className="w-full bg-gray-200 py-20 flex justify-center">
                                <div className='w-11/12 sm:w-3/4 p-4 sm:p-12 bg-white rounded-2xl'>
                                    <div className="sm:px-6 px-2 py-3">
                                        <IoArrowBackCircleOutline className='w-7 h-auto text-gray-600 hover:text-gray-800 cursor-pointer' onClick={closeDetails} />
                                    </div>
                                    <div className="p-2 sm:flex">
                                        <div className="w-full sm:w-1/3 sm:p-6">
                                            <div className="pb-6 border-b w-full border-gray-300">
                                                <a href={selectedDocument?.url} target='_blank' className="text-2xl cursor-pointer hover:underline font-medium pb-2">{selectedDocument.translatedTitle}</a>
                                                <div className="text-sm pt-2 flex">
                                                    <p className='text-gray-600'>{selectedDocument?.author} - {selectedDocument?.year}</p>
                                                </div>
                                                <div className="grid w-5/6 py-5 grid-cols-3">
                                                    <div className="flex justify-center">
                                                        <button href={selectedDocument?.url} target='_blank' rel="noopener noreferrer">
                                                            <RiFilePdf2Fill className="w-5 h-auto text-red-500 cursor-pointer hover:text-red-700" />
                                                        </button>

                                                    </div>
                                                    <div className="flex justify-center">
                                                        <PiQuotesBold
                                                            onClick={() => showCitationPopup(selectedDocument)}
                                                            className="w-5 h-auto text-gray-600 cursor-pointer hover:text-gray-800" />
                                                    </div>
                                                    {savePaper ?
                                                        <div className="flex justify-center">
                                                            <PiCheckCircleBold
                                                                className="h-5 w-auto text-green-500" />
                                                        </div> :
                                                        <div className="flex justify-center">
                                                            <button className='px-3 py-2 rounded-2xl bg-gray-200 flex items-center hover:bg-gray-100'>
                                                                <PiBookBookmarkBold
                                                                    onClick={() => saveToFirebase(selectedDocument)}
                                                                    className="w-5 h-auto text-gray-600 cursor-pointer hover:text-gray-800" />
                                                                <p className='text ml-2 text-gray-600 font-medium'>Save</p>
                                                            </button>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="w-full py-6">
                                                {selectedDocument?.journal?.name
                                                    &&
                                                    <div className="w-full py-3 px-1">
                                                        <div>
                                                            <p className="text-gray-500 font-medium"> {selectedLanguage === "es-ES" ? "Revista" : "Journal Name"} </p>
                                                        </div>
                                                        <div>
                                                            <p className='font-medium text-lg'>{selectedDocument?.journal?.name}</p>
                                                        </div>
                                                    </div>
                                                }
                                                {selectedDocument?.citationCount &&
                                                    <div className="w-full grid py-3 grid-cols-2">
                                                        <div>
                                                            <p className="text-gray-500 font-medium">{selectedLanguage === "es-ES" ? "Citas" : "Cites"}</p>
                                                        </div>
                                                        <div>
                                                            <p className='font-medium'> {selectedDocument?.citationCount} </p>
                                                        </div>
                                                    </div>
                                                }
                                                {selectedDocument?.fieldsOfStudy &&
                                                    <div className="w-full grid py-3 grid-cols-">
                                                        <div>
                                                            <p className="text-gray-500 font-medium">{selectedLanguage === "es-ES" ? "Campos de estudio" : "Fields of study"} </p>
                                                        </div>
                                                        <div>
                                                            <p className='font-medium'> {selectedDocument?.fieldsOfStudy.join(", ")} </p>
                                                        </div>
                                                    </div>
                                                }
                                                {selectedDocument?.journal?.pages &&
                                                    <div className="w-full grid py-3 grid-cols-2 px-1">
                                                        <div>
                                                            <p className="text-gray-500 font-medium"> {selectedLanguage === "es-ES" ? "Páginas" : "Pages"} </p>
                                                        </div>
                                                        <div>
                                                            <p className='font-medium'>{selectedDocument?.journal?.pages}</p>
                                                        </div>
                                                    </div>
                                                }
                                                {selectedDocument?.journal?.volume &&
                                                    <div className="w-full grid py-3 grid-cols-2 px-1">
                                                        <div>
                                                            <p className="text-gray-500 font-medium"> {selectedLanguage === "es-ES" ? "Volúmen" : "Volume"} </p>
                                                        </div>
                                                        <div>
                                                            <p className='font-medium'>{selectedDocument?.journal?.volume}</p>
                                                        </div>
                                                    </div>
                                                }
                                                {selectedDocument?.referenceCount &&
                                                    <div className="w-full grid py-3 grid-cols-2 px-1">
                                                        <div>
                                                            <p className="text-gray-500 font-medium"> {selectedLanguage === "es-ES" ? "Referencias" : "Reference Count"} </p>
                                                        </div>
                                                        <div>
                                                            <p className='font-medium'>{selectedDocument?.referenceCount}</p>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="w-full sm:border-l border-gray-300 sm:w-2/3 sm:p-6">
                                            <div className='flex items-center'>
                                                <a href={selectedDocument?.url} target="_blank">
                                                    <button className="flex items-center"> <HiMiniArrowTopRightOnSquare className="w-6 h-auto text-green-500 hover:text-green-700 cursor-pointer" /> {selectedLanguage === "es-ES" ? "Ir al documento" : "Go to source"} </button>
                                                </a>
                                            </div>
                                            <p className="text-gray-500 font-semibold pt-6 text-lg pb-2">Abstract</p>
                                            <p className="text-justify"> {selectedDocument?.translatedAbstract} </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </>
                    )}
                    {chatDoc && selectedPaper && (
                        <>
                            <div className='w-full flex min-h-screen'>
                                <aside className="w-0 sm:w-1/6 bg-gray-100 p-4 hidden sm:flex flex-col">
                                    <nav>
                                        <ul className="px-4 py-8">
                                            <li className="py-5">
                                                <a onClick={closeChat} className="text-gray-600 hover:text-gray-800 cursor-pointer font-semibold flex items-center">
                                                    <PiArrowCircleLeft className="inline-block w-5 h-auto mr-2" />
                                                    {selectedLanguage === "es-ES" ? "Regresar" : "Go back"}
                                                </a>
                                            </li>
                                            <li className="py-5">
                                                <a href="" className="text-gray-600 hover:text-gray-800 cursor-pointer font-semibold flex items-center">
                                                    <PiPlusCircleBold className="inline-block w-5 h-auto mr-2" />
                                                    {selectedLanguage === "es-ES" ? "Chat nuevo" : "New chat"}
                                                </a>
                                            </li>
                                            {indChat &&
                                                <button onClick={showComparePapers} className="py-5">
                                                    <a className="text-gray-600 font-semibold">
                                                        <PiSparkleBold className="inline-block w-5 h-auto mr-2 text-gray-600" />
                                                        {selectedLanguage === "es-ES" ? "Extraer info" : "Extract"}
                                                    </a>
                                                </button>}

                                            {comparePapers &&
                                                <button className="py-5" onClick={individualChat}>
                                                    <a className="text-gray-600 font-semibold">
                                                        <PiSparkleBold className="inline-block w-5 h-auto mr-2 text-gray-600" />
                                                        Extract
                                                    </a>
                                                </button>}
                                        </ul>
                                    </nav>
                                    <div className="flex-grow overflow-y-auto mt-4">
                                        <h2 className="font-semibold text-lg mb-2">Chat History</h2>
                                    </div>
                                </aside>
                                <div className='bg-white p-2 rounded-xl sm:px-8 sm:pt-16 w-full sm:w-5/6 flex flex-col'>
                                    <div>
                                        <div className='sm:flex items-center pt-2 pb-6'>
                                            <div className='w-full pb-6 sm:pb-0 sm:pr-2 sm:w-3/5'>
                                                <a target='_blank' href={selectedPaper?.openAccessPdf?.url}
                                                    className='text-blue-600 line-clamp-2 font-medium text-sm sm:text-lg cursor-pointer hover:underline'>
                                                    {selectedPaper?.translatedTitle}</a>
                                            </div>
                                            <div className='w-1/2 flex items-center justify-evenly sm:w-2/5'>

                                                <a target='_blank' href={selectedPaper?.openAccessPdf?.url} >
                                                    <img src={pdfOne} className='w-6 h-auto' />
                                                </a>
                                                {getPDF &&
                                                    <FaSpinner className='animate-spin w-4 sm:w-5 h-4 sm:h-5 sm:ml-2 text-gray-500' />
                                                }
                                                {ready &&
                                                    <img src={checkgreenOne} className='w-6 h-auto' />}
                                            </div>
                                        </div>
                                    </div>
                                    {displayError &&
                                        <div className='flex justify-center p-6'>
                                            <p className='font-semibold text-gray-600'> {selectedLanguage === "es-ES" ? "Ha occurido un error. Descargue el PDF manualmente y chatee con este en la sección Chat" : "An error has occurred. Download the PDF manually and chat with it in the Chat section"} </p>
                                        </div>
                                    }
                                    {indChat &&
                                        <div className='flex flex-col flex-grow'>
                                            <div className='flex-grow overflow-y-auto'>
                                                <div className="flex w-full flex-col gap-4">
                                                    {messages?.map((message, index) => (
                                                        <div key={index} className={`${message.type === "user" ? "text-justify sm:px-12 2xl:px-20 p-2 text-gray-500" : "text-justify"} p-2 sm:px-12 2xl:px-20`}>
                                                            {message.type === "user" ? (
                                                                <p className='text-gray-600 font-semibold'> {user?.displayName} </p>
                                                            ) : (
                                                                <p className='text-blue-600 font-semibold'>VortiX</p>
                                                            )}
                                                            <div>
                                                                {message.text != 'loading' && message.text}
                                                                {message.type === "api" && message.text === 'loading' && (
                                                                    <div className='flex w-full justify-start'>
                                                                        <div class=" shadow rounded-md m p-4 w-11/12 bg-white sm:w-1/2">
                                                                            <div class="animate-pulse flex space-x-4">
                                                                                <div class="flex-1 space-y-12 py-2">
                                                                                    <div class="space-y-3 py-8 border border-gray-100 rounded-lg">
                                                                                        <div class="h-3 bg-indigo-200 rounded col-span-2"></div>
                                                                                        <div class="grid grid-cols-3 gap-4">
                                                                                            <div class="h-3 bg-indigo-200 rounded col-span-2"></div>
                                                                                            <div class="h-3 bg-indigo-200 rounded col-span-2"></div>
                                                                                            <div class="h-3 bg-indigo-200 rounded col-span-1"></div>
                                                                                        </div>
                                                                                        <div class="h-3 bg-indigo-200 rounded"></div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className='bg-white px-4 sm:px-40 py-4 sticky bottom-0 shadow-md'>
                                                <form onSubmit={(e) => sendRequest(e, selectedPaper)} className="flex px-4 items-center rounded-3xl bg-gray-200">
                                                    <input
                                                        placeholder={selectedLanguage === "es-ES" ? "Empieza a escribir acá..." : "Start typing here..."}
                                                        value={questionx}
                                                        onChange={(e) => setQuestionx(e.target.value)}
                                                        className="flex-grow border bg-transparent rounded-3xl p-4 mr-4 outline-none text-gray-600"
                                                    />
                                                    <HiPaperAirplane
                                                        onClick={(e) => sendRequest(e, selectedPaper)}
                                                        className="w-6 h-6 text-gray-400 hover:text-gray-500" />
                                                </form>
                                            </div>
                                        </div>
                                    }
                                    {comparePapers &&
                                        <>
                                            <div className="w-full sm:w-1/2 py-6 flex items-center justify-start">
                                                <p className="font-semibold text-gray-600 mt-4 text-lg">
                                                    {selectedLanguage === "es-ES" ? "Selecciona el tipo de información que deseas extraer" : "Select the information you want to extract"}
                                                </p>
                                            </div>
                                            <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-4">
                                                <div className="flex items-center my-3">
                                                    <input type="checkbox" checked={Findings} onChange={() => setFindings(!Findings)} className="mr-2" />
                                                    <button className="px-5 py-1 rounded-3xl hover:bg-blue-200 text-blue-600 bg-blue-100">
                                                        {selectedLanguage === "es-ES" ? "Hallazgos principales" : "Main findings"}
                                                    </button>
                                                </div>
                                                <div className="flex items-center my-3">
                                                    <input type="checkbox" checked={Topic} onChange={() => setTopic(!Topic)} className="mr-2" />
                                                    <button className="px-5 py-1 rounded-3xl hover:bg-blue-200 text-blue-600 bg-blue-100">
                                                        {selectedLanguage === "es-ES" ? "Metodologías" : "Methodologies"}
                                                    </button>
                                                </div>
                                                <div className="flex items-center my-3">
                                                    <input type="checkbox" checked={outcomeMeasured} onChange={() => setOutcomeMeasured(!outcomeMeasured)} className="mr-2" />
                                                    <button className="px-5 py-1 rounded-3xl hover:bg-blue-200 text-blue-600 bg-blue-100">
                                                        {selectedLanguage === "es-ES" ? "Resultados medidos" : "Outcome measured"}
                                                    </button>
                                                </div>
                                                <div className="flex items-center my-3">
                                                    <input type="checkbox" checked={Limitations} onChange={() => setLimitations(!Limitations)} className="mr-2" />
                                                    <button className="px-5 py-1 rounded-3xl hover:bg-blue-200 text-blue-600 bg-blue-100">
                                                        {selectedLanguage === "es-ES" ? "Limitaciones" : "Limitations"}
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-4 items-center">
                                                <div className="flex items-center py-3">
                                                    <input
                                                        value={customValue}
                                                        onChange={(e) => setCustomValue(e.target.value)}
                                                        onKeyDown={(e) => handleCustomFieldChange(e)}
                                                        className="rounded-2xl text-sm outline-none border border-blue-300 p-2 w-full"
                                                        placeholder="Add fields"
                                                    />
                                                </div>
                                                {customFields.length > 0 && (
                                                    customFields.map((field, index) => (
                                                        <div key={index} className="flex items-center my-3">
                                                            <FaTimesCircle
                                                                className="text-red-500 hover:text-red-700 cursor-pointer w-4 h-auto mr-2"
                                                                onClick={() => removeCustomField(field)}
                                                            />
                                                            <button className="px-5 py-1 rounded-3xl hover:bg-blue-200 text-blue-600 bg-blue-100">
                                                                {field}
                                                            </button>
                                                        </div>
                                                    ))
                                                )}
                                            </div>
                                            <div className="flex items-center justify-start pt-6 pb-12">
                                                <button
                                                    name="extractInfo"
                                                    onClick={sendRequest2}
                                                    className="border border-blue-500 text-blue-500 font-semibold px-5 py-2 rounded-3xl hover:bg-blue-100"
                                                >
                                                    {selectedLanguage === "es-ES" ? "Empezar" : "Start"}
                                                </button>
                                            </div>
                                            {retrievingCustomData && (
                                                <div className="flex justify-center">
                                                    <p className="py-8">
                                                        {selectedLanguage === "es-ES" ? "Cargando..." : "Loading..."}
                                                    </p>
                                                </div>
                                            )}
                                            {apiAnswer && (
                                                <div className="container mx-auto">
                                                    <div className="block sm:hidden">
                                                        {Object.entries(paperData).map(([key, value]) => (
                                                            <div key={key} className="border-b last:border-b-0">
                                                                <div className="font-bold py-2 px-4 bg-gray-100">{key}</div>
                                                                <div className="py-2 px-4">{value}</div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className="hidden sm:block">
                                                        <table className="table-auto w-full">
                                                            <thead>
                                                                <tr>
                                                                    <th className="px-4 py-2">Field</th>
                                                                    <th className="px-4 py-2">Value</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {Object.entries(paperData).map(([key, value]) => (
                                                                    <tr key={key}>
                                                                        <td className="border px-4 py-2 font-bold">{key}</td>
                                                                        <td className="border px-4 py-2">{value}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            )}
                                        </>

                                    }
                                </div>
                            </div>
                        </>

                    )}
                    {mainWebsite &&
                        <div className='flex flex-row h-screen'>
                            <aside className="w-0 sm:w-64 bg-gray-100 overflow-y-auto p-4 hidden sm:flex flex-col fixed h-full">
                                <nav className="flex-grow">
                                    <ul className="flex flex-col items-center py-6">
                                        <div className=''>
                                            <div className="flex cursor-pointer flex-col items-center sm:flex-row sm:items-center sm:space-x-2 sm:mb-0">
                                                <p onClick={clearAll} className="text-lg cursor-pointer text-gray-800  font-bold mb-2 sm:mb-0">VortiX</p>
                                                <button onClick={clearAll} className="bg-indigo-600 text-white px-4 py-2 rounded-full text-sm flex items-center hover:bg-indigo-700 transition duration-300">
                                                    Experimental <BeakerIcon className="w-4 h-4" />
                                                </button>
                                            </div>
                                            <List user={user} selectedLanguage={selectedLanguage} savedDocs={savedDocs} />
                                        </div>
                                    </ul>
                                </nav>
                                <div className="flex flex-col items-center space-y-6 pb-6">
                                    <div className='flex justify-evenly space-x-8'>
                                        <div className="w-full flex items-center justify-center">
                                            {user ? (
                                                <div className="flex items-center">
                                                    {user.photoURL ? (
                                                        <>
                                                            <img
                                                                onClick={handleLogout}
                                                                className="w-6 h-6 rounded-full cursor-pointer object-cover"
                                                                src={user.photoURL}
                                                                onError={(e) => {
                                                                    e.target.onerror = null;
                                                                    e.target.src = '../assets/icons/users.svg';
                                                                }}
                                                                alt="User profile"
                                                            />
                                                            <p className='px-1'>
                                                                {user?.displayName}
                                                            </p>
                                                        </>
                                                    ) : (
                                                        <UserCircleIcon onClick={handleLogout} className="w-6 h-6 text-gray-500 cursor-pointer hover:text-indigo-600" />
                                                    )}
                                                </div>
                                            ) : (
                                                <UserCircleIcon onClick={signIn} className="w-6 h-6 text-gray-500 cursor-pointer hover:text-indigo-600" />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </aside>
                            <div className="w-full flex justify-center h-full">
                                <div className='w-full sm:pl-64'>
                                    <div className="flex cursor-pointer sm:hidden pt-6 items-center justify-center">
                                        <p onClick={clearAll} className="text-lg cursor-pointer font-bold pr-2 text-gray-800 sm:mb-0">VortiX</p>
                                        <button onClick={clearAll} className="bg-indigo-600 text-white px-4 py-2 rounded-full text-sm flex items-center hover:bg-indigo-700 transition duration-300">
                                            Experimental <BeakerIcon className="w-4 h-4" />
                                        </button>
                                    </div>
                                    <div className='w-full'>
                                        <div className='sm:flex w-full justify-center pt-10'>
                                            <div className='w-0 sm:w-1/5'></div>
                                            <div className='w-full sm:w-3/5 flex justify-center'>
                                                <form onSubmit={(e) => { e.preventDefault(); if (!loading) { handleSearch(); } }}
                                                    className='flex rounded-3xl mt-6 sm:mt-0 border items-center border-gray-300 p-2 bg-white justify-evenly w-11/12 sm:w-full' >
                                                    <input
                                                        placeholder='Ask a follow-up question...'
                                                        value={searchTerm}
                                                        onChange={(e) => setSearchTerm(e.target.value)}
                                                        className='w-5/6 flex bg-transparent outline-none p-2' disabled={loading} />
                                                    <XIcon onClick={clear} className='w-4 h-4 text-gray-500 hover:text-gray-700 cursor-pointer' />
                                                    <button onClick={(e) => { e.preventDefault(); if (!loading) { handleSearch(); } }} disabled={loading} >
                                                        <FaSearch className='text-blue-500 cursor-pointer hover:text-blue-700 w-5 h-5' /> </button>
                                                </form>
                                            </div>
                                            <div className='w-full sm:w-1/5 flex justify-center'>
                                                <div className='flex mt-6 items-center sm:mt-0 w-full justify-evenly'>
                                                    <PiBookmarksSimpleFill onClick={() => { Navigate("/saved") }} className='h-8 w-8 text-blue-600 cursor-pointer hover:text-blue-500' />

                                                    <LuSparkles onClick={openPopup} className='h-8 w-8 text-purple-600 cursor-pointer hover:text-purple-500' />

                                                    {user ? (
                                                        <>
                                                            {user.photoURL ? (
                                                                <img onClick={handleLogout} className='h-8 w-8 rounded-full cursor-pointer' src={user.photoURL} alt="User Profile" />
                                                            ) : (
                                                                <UserCircleIcon onClick={handleLogout} className='h-8 w-8 text-yellow-600 cursor-pointer hover:text-blue-500' />
                                                            )}
                                                        </>
                                                    ) : (
                                                        <UserCircleIcon onClick={signIn} className='h-8 w-8 text-blue-600 cursor-pointer hover:text-blue-500' />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex justify-center'>
                                        <div className='w-11/12 pt-5 sm:w-4/5'>
                                            <div className='w-4/5 space-x-5 flex items-center'>
                                                <div>
                                                    <button onClick={() => setIsOpen(!isOpen)}
                                                        className={`${isOpen ? "bg-white cursor-not-allowed" : ""} border border-gray-300 px-4 py-1 flex items-center rounded-3xl text-gray-500`}>
                                                        <MdOutlineFilterList className='w-4 mr-1 h-auto' />
                                                        <p className='hidden sm:flex'>{selectedLanguage === "es-ES" ? "Filtros" : "Filters"} </p>
                                                    </button>
                                                </div>
                                                <div>
                                                    <button onClick={() => { Navigate("/chat") }}
                                                        className='border border-gray-300 px-4 py-1 flex items-center rounded-3xl text-gray-500'>
                                                        <PiChatsTeardrop className='w-4 mr-1 h-auto' />
                                                        <p className='hidden sm:flex font-light'>Chat</p>
                                                    </button>
                                                </div>
                                                <div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex justify-center'>
                                        <div className='w-11/12 pt-5 sm:w-4/5'>
                                            {rs ? <>
                                                <div className='flex w-full justify-center'>
                                                    <div className='flex w-full justify-center'>
                                                        <div class="my-6 p-4 w-11/12 bg-white sm:w-4/5">
                                                            <div class="animate-pulse flex space-x-4">
                                                                <div class="flex-1 space-y-12 py-2">
                                                                    <div class="space-y-3 py-8 border border-gray-100 rounded-lg">
                                                                        <div class="h-3 bg-indigo-200 rounded col-span-2"></div>
                                                                        <div class="grid grid-cols-3 gap-4">
                                                                            <div class="h-3 bg-indigo-200 rounded col-span-2"></div>
                                                                            <div class="h-3 bg-indigo-200 rounded col-span-2"></div>
                                                                            <div class="h-3 bg-indigo-200 rounded col-span-1"></div>
                                                                        </div>
                                                                        <div class="h-3 bg-indigo-200 rounded"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </> :
                                                <div className='w-4/5'>
                                                    <ul>
                                                        {results?.slice(0, 10).map((result, index) => (
                                                            <li onClick={(e) => retrieveData(e, result)} key={index} className="mb-2 text-sm cursor-pointer hover:font-semibold text-gray-600 hover:text-gray-800">
                                                                {result.name || 'Unnamed result'}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            }
                                            {dg ? <>
                                                <div className='flex w-full justify-center'>
                                                    <div className='flex w-full justify-center'>
                                                        <div class="my-6 p-4 w-11/12 bg-white sm:w-4/5">
                                                            <div class="animate-pulse flex space-x-4">
                                                                <div class="flex-1 space-y-12 py-2">
                                                                    <div class="space-y-3 py-8 border border-gray-100 rounded-lg">
                                                                        <div class="h-3 bg-indigo-200 rounded col-span-2"></div>
                                                                        <div class="grid grid-cols-3 gap-4">
                                                                            <div class="h-3 bg-indigo-200 rounded col-span-2"></div>
                                                                            <div class="h-3 bg-indigo-200 rounded col-span-2"></div>
                                                                            <div class="h-3 bg-indigo-200 rounded col-span-1"></div>
                                                                        </div>
                                                                        <div class="h-3 bg-indigo-200 rounded"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </> :
                                                <div>
                                                    {additionalData && (
                                                        <div className="mt-4">
                                                            <div className="text-lg py-3 font-semibold">
                                                                {additionalData?.slice(0, 1)?.map((result, index) => (
                                                                    <div key={index}>
                                                                        <p>{result?.country?.value} - {result?.indicator?.value}</p>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <div className="flex justify-center">
                                                                <ResponsiveContainer width="80%" height={400}>
                                                                    <LineChart data={additionalData}>
                                                                        <CartesianGrid strokeDasharray="3 3" />
                                                                        <XAxis dataKey="date" />
                                                                        <YAxis />
                                                                        <Tooltip />
                                                                        <Legend />
                                                                        <Line type="monotone" dataKey="value" stroke="#82ca9d" activeDot={{ r: 8 }} />
                                                                    </LineChart>
                                                                </ResponsiveContainer>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        {loadingAzureSearch ?
                                            <div className='flex w-full justify-center'>
                                                <div class=" shadow rounded-md my-6 p-4 w-11/12 bg-white sm:w-4/5">
                                                    <div class="animate-pulse flex space-x-4">
                                                        <div class="flex-1 space-y-12 py-2">
                                                            <div class="space-y-3 py-8 border border-gray-100 rounded-lg">
                                                                <div class="h-3 bg-indigo-200 rounded col-span-2"></div>
                                                                <div class="grid grid-cols-3 gap-4">
                                                                    <div class="h-3 bg-indigo-200 rounded col-span-2"></div>
                                                                    <div class="h-3 bg-indigo-200 rounded col-span-2"></div>
                                                                    <div class="h-3 bg-indigo-200 rounded col-span-1"></div>
                                                                </div>
                                                                <div class="h-3 bg-indigo-200 rounded"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> :
                                            <>
                                                {roberta ?
                                                    <div className='flex justify-center'>
                                                        <div className='w-11/12 mt-8 bg-white sm:w-4/5'>
                                                            <div className='flex justify-between'>
                                                                <div>
                                                                </div>
                                                                <div>
                                                                </div>
                                                            </div>
                                                            <div className={`w-full pt-6`}>
                                                            {(data?.yes > 0 || data?.maybe > 0 || data?.no > 0) &&
                                                                (
                                                            <div className='grid grid-cols-1 grid-cols-2'>
                                                                    <div className='w-full p-2'>
                                                                        {(data?.yes || data?.maybe || data?.no) &&
                                                                            <p className='text-gray-800  text-sm sm:text-base font-semibold pb-2'>{selectedLanguage === "es-ES" ? "Análisis de papers" : "Papers analysis"} </p>
                                                                        }
                                                                        {data?.yes !== undefined && (
                                                                            <>
                                                                                <a className='my-2 flex items-center'>
                                                                                    <CheckCircleIcon className='text-green-500 w-5 h-5 mr-2' />
                                                                                    <p className='text-gray-500 text-sm sm:text-base '>
                                                                                        {selectedLanguage === "es-ES" ? "Sí: " : "Yes: "}{Math.round((data.yes / (data.yes + data.maybe + data.no)) * 100)}%
                                                                                    </p>
                                                                                </a>
                                                                                <div className='my-2'>
                                                                                    <div className="bg-gray-200 h-3 w-full rounded">
                                                                                        <div
                                                                                            className="bg-green-500 mb-6 h-3 rounded"
                                                                                            style={{ width: `${(data.yes / (data.yes + data.maybe + data.no)) * 100}%` }}
                                                                                        ></div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        )}

                                                                        {data?.maybe !== undefined && (
                                                                            <>
                                                                                <a className='my-2 flex items-center'>
                                                                                    <QuestionMarkCircleIcon className='text-yellow-500 w-5 h-5 mr-2' />
                                                                                    <p className='text-gray-500 text-sm sm:text-base '>
                                                                                        {selectedLanguage === "es-ES" ? "Quizás: " : "Maybe: "} {Math.round((data.maybe / (data.yes + data.maybe + data.no)) * 100)}%
                                                                                    </p>
                                                                                </a>
                                                                                <div className='my-2'>
                                                                                    <div className="bg-gray-200 h-3 w-full rounded">
                                                                                        <div
                                                                                            className="bg-yellow-500 mb-6 h-3 rounded"
                                                                                            style={{ width: `${(data.maybe / (data.yes + data.maybe + data.no)) * 100}%` }}
                                                                                        ></div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                        {data?.no !== undefined && (
                                                                            <>
                                                                                <a className='my-2 flex items-center'>
                                                                                    <XCircleIcon className='text-red-500 w-5 h-5 mr-2' />
                                                                                    <p className='text-gray-500 text-sm sm:text-base '>
                                                                                        {selectedLanguage === "es-ES" ? "No: " : "No: "} {Math.round((data.no / (data.yes + data.maybe + data.no)) * 100)}%
                                                                                    </p>
                                                                                </a>
                                                                                <div className='my-2'>
                                                                                    <div className="bg-gray-200 h-3 w-full rounded">
                                                                                        <div
                                                                                            className="bg-red-500 mb-6 h-3 rounded"
                                                                                            style={{ width: `${(data.no / (data.yes + data.maybe + data.no)) * 100}%` }}
                                                                                        ></div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                    <div className='flex items-center justify-center'>
                                                                        <a target='_blank' href='https://huggingface.co/cross-encoder/nli-distilroberta-base' 
                                                                        className="px-5 py-2 text-white font-semibold rounded-3xl bg-indigo-600 hover:bg-indigo-500"> 
                                                                        {selectedLanguage=="es-ES"? "Usando NLI" : "NLI used"} </a>
                                                                    </div>
                                                                </div>
                                                                )
                                                            }
                                                                <div className='w-full mb-4'>
                                                                    <div className='w-full flex mb-3 w-1/3 justify-between'>
                                                                        <div className='flex items-center'>
                                                                        <Sparkles className={`mr-2 text-indigo-600 ${receiving? "animate-spin" : ""} w-6 h-auto`} />
                                                                            <p className='text-gray-800 text-sm sm:text-lg font-medium'> {selectedLanguage === "es-ES" ? "Resumen" : "Summary"}</p>
                                                                        </div>
                                                                        <div className='flex w-1/3 justify-end space-x-5'>
                                                                            <CopyIcon
                                                                                className={`w-5 h-auto ${copySuccess ? 'text-green-500' : 'text-indigo-400'} cursor-pointer hover:text-purple-800`}
                                                                                onClick={handleCopy}
                                                                            />
                                                                            <button onClick={(e) => downloadResultsAsCSV(e)} >
                                                                                <FiDownload className='w-5 h-auto text-indigo-400 cursor-pointer hover:text-purple-800' />
                                                                            </button>
                                                                            <a href='https://forms.gle/foK22fLXnvrTgFXq5' target='_blank'>
                                                                                <VscFeedback className='w-5 h-auto text-indigo-400 cursor-pointer hover:text-purple-800' />
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                    {loadingAzureSearch ? (
                                                                        <div className="flex items-center justify-start space-x-2">
                                                                            <div className="w-2 h-2 rounded-full bg-blue-500 animate-pulse"></div>
                                                                            <div className="w-2 h-2 rounded-full bg-blue-500 animate-pulse delay-75"></div>
                                                                            <div className="w-2 h-2 rounded-full bg-blue-500 animate-pulse delay-150"></div>
                                                                        </div>
                                                                    ) : (
                                                                        <>
                                                                            <p className="text-justify">
                                                                                {typeof summaryx === 'string' ? (
                                                                                    summaryx.split(/(\[doc\d+\])/).map((part, partIndex) => {
                                                                                        const match = part.match(/^\[doc(\d+)\]$/);
                                                                                        if (match) {
                                                                                            const number = match[1];
                                                                                            return (
                                                                                                <span
                                                                                                    key={partIndex}
                                                                                                    className="inline-flex items-center justify-center w-6 h-6 rounded-full cursor-pointer bg-blue-100 text-blue-800 text-xs font-medium"
                                                                                                    onClick={() => setSelectedSourceIndex(parseInt(number) - 1)}
                                                                                                >
                                                                                                    {number}
                                                                                                </span>
                                                                                            );
                                                                                        }
                                                                                        return part;
                                                                                    })
                                                                                ) : (
                                                                                    summaryx
                                                                                )}
                                                                            </p>
                                                                            {selectedSourceIndex !== null && (
                                                                                <div className="bg-gray-100 text-gray-800 font-sans rounded-lg p-3 mt-2">
                                                                                    <button
                                                                                        className="top-2 right-2 text-gray-500 hover:text-gray-700"
                                                                                        onClick={() => setSelectedSourceIndex(null)}
                                                                                    >
                                                                                        <FaChevronDown className="h-5 w-5" />
                                                                                    </button>
                                                                                    <a onClick={() => getLinks(fuentes[selectedSourceIndex]?.content)} className="text-sm cursor-pointer line-clamp-3 text-justify hover:underline">{fuentes[selectedSourceIndex]?.content}</a>
                                                                                </div>
                                                                            )}
                                                                            {loadingAzureSearch ? <></> :
                                                                                <div className=''>
                                                                                    <div className='flex justify-start'>
                                                                                        <div className='my-4'>
                                                                                            <div className=''>
                                                                                                <button onClick={view ? () => setView(false) : viewSources}
                                                                                                    className='flex items-center text-gray-800 font-medium hover:text-gray-900 underline'>
                                                                                                    {view ? <RiArrowDownSLine className='mr-2 text-gray-600 cursor-pointer w-6 h-auto' /> : <RiArrowRightSLine className='mr-2 cursor-pointer w-6 h-auto' />}
                                                                                                    {view ? selectedLanguage === "es-ES" ? "Bibliografía" : "Bibliography" : selectedLanguage === "es-ES" ? "Bibliografía" : "Bibliography"}
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {view &&
                                                                                        <div className='mb-8'>
                                                                                            {fuentes?.map((result, index) => (
                                                                                                <div onClick={() => getLinks(result?.content)} className="flex py-1 items-center" key={index}>
                                                                                                    <div className='w-11/12'>
                                                                                                        <p className='flex items-center'>
                                                                                                            <a className='py-1 px-2 text-xs rounded-full border-2 border-indigo-600 mr-2 text-indigo-600'>
                                                                                                                {index + 1}
                                                                                                            </a>
                                                                                                            <p className='hover:text-blue-600 bg-white text-sm line-clamp-1 cursor-pointer underline'>
                                                                                                                {result?.content}
                                                                                                            </p>
                                                                                                        </p>
                                                                                                    </div>
                                                                                                    <div className='w-1/12'>
                                                                                                        <SearchIcon className='w-3 h-auto text-blue-500 cursor-pointer ml-2' />
                                                                                                    </div>
                                                                                                </div>
                                                                                            ))}
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            }
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <>
                                                        {(searchTerm.split(" ").length > 2) &&
                                                            <div className=''>
                                                                <div className='flex w-full justify-center'>
                                                                    <div class="my-6 p-4 w-11/12 bg-white sm:w-4/5">
                                                                        <div class="animate-pulse flex space-x-4">
                                                                            <div class="flex-1 space-y-12 py-2">
                                                                                <div class="space-y-3 py-8 border border-gray-100 rounded-lg">
                                                                                    <div class="h-3 bg-indigo-200 rounded col-span-2"></div>
                                                                                    <div class="grid grid-cols-3 gap-4">
                                                                                        <div class="h-3 bg-indigo-200 rounded col-span-2"></div>
                                                                                        <div class="h-3 bg-indigo-200 rounded col-span-2"></div>
                                                                                        <div class="h-3 bg-indigo-200 rounded col-span-1"></div>
                                                                                    </div>
                                                                                    <div class="h-3 bg-indigo-200 rounded"></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                }
                                            </>
                                        }
                                        <div className='w-full'>
                                            {loading &&
                                                <div className='w-full'>
                                                    <div className='flex w-full justify-center'>
                                                        <div className='flex w-full justify-center'>
                                                            <div class="my-6 p-4 w-11/12 bg-white sm:w-4/5">
                                                                <div class="animate-pulse flex space-x-4">
                                                                    <div class="flex-1 space-y-12 py-2">
                                                                        <div class="space-y-3 py-8 border border-gray-100 rounded-lg">
                                                                            <div class="h-3 bg-indigo-200 rounded col-span-2"></div>
                                                                            <div class="grid grid-cols-3 gap-4">
                                                                                <div class="h-3 bg-indigo-200 rounded col-span-2"></div>
                                                                                <div class="h-3 bg-indigo-200 rounded col-span-2"></div>
                                                                                <div class="h-3 bg-indigo-200 rounded col-span-1"></div>
                                                                            </div>
                                                                            <div class="h-3 bg-indigo-200 rounded"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='flex justify-center'>
                                                        <div className='flex w-full justify-center'>
                                                            <div class="my-6 p-4 w-11/12 bg-white sm:w-4/5">
                                                                <div class="animate-pulse flex space-x-4">
                                                                    <div class="flex-1 space-y-12 py-2">
                                                                        <div class="space-y-3 py-8 border border-gray-100 rounded-lg">
                                                                            <div class="h-3 bg-indigo-200 rounded col-span-2"></div>
                                                                            <div class="grid grid-cols-3 gap-4">
                                                                                <div class="h-3 bg-indigo-200 rounded col-span-2"></div>
                                                                                <div class="h-3 bg-indigo-200 rounded col-span-2"></div>
                                                                                <div class="h-3 bg-indigo-200 rounded col-span-1"></div>
                                                                            </div>
                                                                            <div class="h-3 bg-indigo-200 rounded"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='flex justify-center'>
                                                        <div className='flex w-full justify-center'>
                                                            <div class="my-6 p-4 w-11/12 bg-white sm:w-4/5">
                                                                <div class="animate-pulse flex space-x-4">
                                                                    <div class="flex-1 space-y-12 py-2">
                                                                        <div class="space-y-3 py-8 border border-gray-100 rounded-lg">
                                                                            <div class="h-3 bg-indigo-200 rounded col-span-2"></div>
                                                                            <div class="grid grid-cols-3 gap-4">
                                                                                <div class="h-3 bg-indigo-200 rounded col-span-2"></div>
                                                                                <div class="h-3 bg-indigo-200 rounded col-span-2"></div>
                                                                                <div class="h-3 bg-indigo-200 rounded col-span-1"></div>
                                                                            </div>
                                                                            <div class="h-3 bg-indigo-200 rounded"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='flex justify-center'>
                                                        <div className='flex w-full justify-center'>
                                                            <div class="my-6 p-4 w-11/12 bg-white sm:w-4/5">
                                                                <div class="animate-pulse flex space-x-4">
                                                                    <div class="flex-1 space-y-12 py-2">
                                                                        <div class="space-y-3 py-8 border border-gray-100 rounded-lg">
                                                                            <div class="h-3 bg-indigo-200 rounded col-span-2"></div>
                                                                            <div class="grid grid-cols-3 gap-4">
                                                                                <div class="h-3 bg-indigo-200 rounded col-span-2"></div>
                                                                                <div class="h-3 bg-indigo-200 rounded col-span-2"></div>
                                                                                <div class="h-3 bg-indigo-200 rounded col-span-1"></div>
                                                                            </div>
                                                                            <div class="h-3 bg-indigo-200 rounded"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='flex justify-center'>
                                                        <div className='flex w-full justify-center'>
                                                            <div class="my-6 p-4 w-11/12 bg-white sm:w-4/5">
                                                                <div class="animate-pulse flex space-x-4">
                                                                    <div class="flex-1 space-y-12 py-2">
                                                                        <div class="space-y-3 py-8 border border-gray-100 rounded-lg">
                                                                            <div class="h-3 bg-indigo-200 rounded col-span-2"></div>
                                                                            <div class="grid grid-cols-3 gap-4">
                                                                                <div class="h-3 bg-indigo-200 rounded col-span-2"></div>
                                                                                <div class="h-3 bg-indigo-200 rounded col-span-2"></div>
                                                                                <div class="h-3 bg-indigo-200 rounded col-span-1"></div>
                                                                            </div>
                                                                            <div class="h-3 bg-indigo-200 rounded"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='flex justify-center'>
                                                        <div className='flex w-full justify-center'>
                                                            <div class="my-6 p-4 w-11/12 bg-white sm:w-4/5">
                                                                <div class="animate-pulse flex space-x-4">
                                                                    <div class="flex-1 space-y-12 py-2">
                                                                        <div class="space-y-3 py-8 border border-gray-100 rounded-lg">
                                                                            <div class="h-3 bg-indigo-200 rounded col-span-2"></div>
                                                                            <div class="grid grid-cols-3 gap-4">
                                                                                <div class="h-3 bg-indigo-200 rounded col-span-2"></div>
                                                                                <div class="h-3 bg-indigo-200 rounded col-span-2"></div>
                                                                                <div class="h-3 bg-indigo-200 rounded col-span-1"></div>
                                                                            </div>
                                                                            <div class="h-3 bg-indigo-200 rounded"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        {webResults && (
                                            <div className='flex justify-center'>
                                                <div className='w-11/12 sm:w-4/5'>
                                                    <a className='flex items-center pt-8 text-purple-500 font-medium mb-6'>
                                                        {selectedLanguage === "es-ES" ? "Resultados web" : "Web results"}
                                                        <BiWorld className="text-green-500 w-5 ml-2 h-auto" />
                                                    </a>
                                                </div>
                                            </div>
                                        )}
                                        {trOpen && <Traduccion trOpen={trOpen} selectedLanguage={selectedLanguage} trBar={trBar} setTrOpen={setTrOpen} />}
                                        {isOpen && <Sidebar yearRange={yearRange} yearBefore={yearBefore} yearAfter={yearAfter} setYearRange={setYearRange} setYearBefore={setYearBefore} setYearAfter={setYearAfter} year={year} setYear={setYear} handleCheckboxChange={handleCheckboxChange} handleSearch={handleSearch} free={free} setFree={setFree} user={user} selectedLanguage={selectedLanguage} setIsOpen={setIsOpen} sidebarRef={sidebarRef} isOpen={isOpen} />}
                                        <>
                                            {loading ? <></> :
                                                <>
                                                    <Result savedPapers={savedPapers} saved={saved} handleSaving={handleSaving} saveToFirebase={saveToFirebase} handlePaperSelection={handlePaperSelection} handleDocumentSelection={handleDocumentSelection} data={data} signIn={signIn} user={user} selectedLanguage={selectedLanguage} recommendations={recommendations} />
                                                </>
                                            }
                                        </>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </>
            }
        </>
    );
};

export default Papers;

const Traduccion = ({ trBar, selectedLanguage, setTrOpen, trOpen }) => {
    const [uploadStatus, setUploadStatus] = useState('idle');
    const [translationResult, setTranslationResult] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');

    const [targetLanguage, setTargetLanguage] = useState("es");

    const handleInputChange = (e) => {
        setTargetLanguage(e.target.value);
    };

    const handleTranslate = async () => {
        const fileInput = document.getElementById('fileInput');
        if (fileInput.files.length > 0) {
            const file = fileInput.files[0];
            const formData = new FormData();
            formData.append('file', file);
            formData.append('targetLanguage', targetLanguage);

            setUploadStatus('loading');

            try {
                const response = await fetch('https://vortex-backend-wyg2.onrender.com/translate', {
                    method: 'POST',
                    body: formData,
                });

                if (response.ok) {
                    const result = await response.json();
                    setTranslationResult(result);
                    console.log(result)
                    setUploadStatus('success');
                } else {
                    throw new Error('Failed to translate');
                }
            } catch (error) {
                setErrorMessage(error.message);
                setUploadStatus('error');
            }
        }
    };


    const close = () => {
        setTrOpen(false)
    }

    return (
        <div
            ref={trBar}
            className={`fixed inset-y-0 right-0 w-96 rounded-l-lg bg-gray-100 transform transition-transform overflow-y-auto ${trOpen ? 'translate-x-0' : '-translate-x-full'}`}>
            <div className="flex justify-center">
                <div className='w-5/6 flex pt-12 justify-between items-center'>
                    <p className="text-xl font-semibold text-gray-700">{selectedLanguage === "es-ES" ? "Traduce" : "Translate"}</p>
                    <XIcon onClick={close} className='text-gray-700 w-5 cursor-pointer h-5 hover:text-gray-900' />
                </div>
            </div>
            <div className='flex justify-center'>
                <div className='w-5/6 pt-10'>
                    <label for="countries" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{selectedLanguage === "es" ? "¿A qué idioma desea traducir?" : "Target language"}</label>
                </div>
            </div>
            <div className='flex items-center justify-center mt-4 mb-10'>
                <div className='w-5/6 '>
                    <form class="">
                        <select value={targetLanguage} onChange={handleInputChange} id="countries" class="bg-gray-50 px-5 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-3/5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                            <option value="es" selected>{selectedLanguage === "es" ? "Español" : "Spanish"}</option>
                            <option value="en">{selectedLanguage === "es" ? "Inglés" : "English"}</option>
                            <option value="de">{selectedLanguage === "es" ? "Alemán" : "German"}</option>
                            <option value="fr">{selectedLanguage === "es" ? "Frances" : "French"}</option>
                            <option value="ru">{selectedLanguage === "es" ? "Ruso" : "Russian"}</option>
                        </select>
                    </form>
                </div>
            </div>
            <div className='flex justify-center'>
                <div className="w-5/6">
                    <label htmlFor="fileInput" className="relative cursor-pointer cursor-pointer bg-white border-2 border-gray-300 rounded w-full px-5 py-2">
                        <input
                            id="fileInput"
                            type="file"
                            className="absolute top-0 left-0 h-full w-full opacity-0"
                        />
                        <span className="text-gray-400"> {selectedLanguage === "es-ES" ? "Seleccione un archivo" : "Select a file"} </span>
                    </label>
                </div>
            </div>
            <div className='flex justify-center py-6'>
                <div className='w-5/6'>
                    <div className='w-3/5 flex justify-center'>
                        <button onClick={handleTranslate} className='bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-5 rounded-lg'>
                            {selectedLanguage === "es-ES" ? "Traducir" : "Translate"}
                        </button>
                    </div>
                </div>
            </div>
            <div className='flex justify-center py-2'>
                {uploadStatus === 'loading' && <p> {selectedLanguage === "es-ES" ? "Cargando..." : "Loading..."} </p>}
                {uploadStatus === 'success' && <p>  {selectedLanguage === "es-ES" ? "Traducción exitosa" : "Translation Successful"} </p>}
                {uploadStatus === 'error' && <p>Error: {errorMessage}</p>}
            </div>
            {uploadStatus === 'success' && translationResult && (
                <div className='text-center p-4'>
                    <p className='text-lg'>Translation:</p>
                    <p className='text-gray-600'>{translationResult.translatedText}</p>
                </div>
            )}

        </div>
    );
};

const Result = ({ user, handleDocumentSelection, savedPapers, saved, handleSaving, saveToFirebase, handlePaperSelection, signIn, data, recommendations, selectedLanguage }) => {

    const [expandedPapers, setExpandedPapers] = useState({});

    const toggleText = (index) => {
        setExpandedPapers(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    const [citationPopup, setCitationPopup] = useState({ show: false, citation: '' });
    const [showCopiedMessage, setShowCopiedMessage] = useState(false);

    const formatCitationAPA = (paper) => {
        const authors = paper?.author?.name || paper?.author || "";
        const year = paper?.year || "";
        const title = paper?.title || "";
        const journal = paper?.journal?.name || "";
        const volume = paper?.volume || "";
        const pages = paper?.pages || "";
        const url = paper?.openAccessPdf?.url || paper?.url;
        return `${authors} (${year}). ${title}. <i>${journal}</i>, ${volume}, ${pages} <br/> <p style="color: #2563eb;">${url}</p>`;
    };

    const formatCitationMLA = (paper) => {
        const authors = paper?.author?.name || paper?.author || "";
        const title = paper?.title || "";
        const journal = paper?.journal?.name || "";
        const volume = paper?.volume || "";
        const number = paper?.number || "";
        const year = paper?.year || "";
        const pages = paper?.pages || "";
        return `${authors}. "${title}." <i>${journal}</i>, vol. ${volume}, no. ${number}, ${year}, pp. ${pages}.`;
    };

    const formatCitationChicago = (paper) => {
        const authors = paper?.author?.name || paper?.author || "";
        const title = paper?.title || "";
        const journal = paper?.journal?.name || "";
        const volume = paper?.volume || "";
        const number = paper?.number || "";
        const year = paper?.year || "";
        const pages = paper?.pages || "";
        return `${authors}. "${title}." <i>${journal}</i> ${volume}, no. ${number} (${year}): ${pages}.`;
    };

    const formatCitationBibTeX = (paper) => {
        const authors = paper?.author?.name || paper?.author || "";
        const title = paper?.title || "";
        const journal = paper?.journal?.name || "";
        const year = paper?.year || "";
        const volume = paper?.volume || "";
        const number = paper?.number || "";
        const pages = paper?.pages || "";
        return `@article{${authors.replace(/\s/g, "")}_${year},\n  author = {${authors}},\n  title = {${title}},\n  journal = {${journal}},\n  year = {${year}},\n  volume = {${volume}},\n  number = {${number}},\n  pages = {${pages}}\n}`;
    };

    const showCitationPopup = (paper) => {
        const citationAPA = formatCitationAPA(paper);
        const citationMLA = formatCitationMLA(paper);
        const citationChicago = formatCitationChicago(paper);
        const citationBibTeX = formatCitationBibTeX(paper);
        setCitationPopup({
            show: true,
            citationAPA,
            citationMLA,
            citationChicago,
            citationBibTeX,
            selectedTab: "APA",
        });
    };

    const closeCitationPopup = () => {
        setCitationPopup({ show: false, citation: '' });
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        setShowCopiedMessage(true);
        setTimeout(() => {
            setShowCopiedMessage(false);
        }, 1500);
    };

    return (
        <>
            {citationPopup.show && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div className="modal-overlay absolute w-full h-full bg-gray-900 bg-opacity-50"></div>
                    <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto shadow-xl z-50">
                        <div className="">
                            <div className="w-full flex bg-gray-100">
                                <div className="w-1/6"></div>
                                <div className="w-4/6 flex justify-center py-3">
                                    <p className="text-lg font-semibold text-gray-700">
                                        {selectedLanguage === "es-ES" ? "Citar fuente" : "Cite source"}
                                    </p>
                                </div>
                                <div className="w-1/6 flex items-center justify-center">
                                    <XCircleIcon
                                        onClick={closeCitationPopup}
                                        className="text-gray-700 h-6 w-6 hover:text-gray-800 cursor-pointer"
                                    />
                                </div>
                            </div>
                            <div className="grid grid-cols-4">
                                <div
                                    className={`py-2 px-4 flex justify-center cursor-pointer ${citationPopup.selectedTab === "APA"
                                        ? "bg-gray-200 text-gray-800"
                                        : "bg-gray-100 text-gray-600"
                                        }`}
                                    onClick={() =>
                                        setCitationPopup({ ...citationPopup, selectedTab: "APA" })
                                    }
                                >
                                    APA
                                </div>
                                <div
                                    className={`py-2 px-4 flex justify-center cursor-pointer ${citationPopup.selectedTab === "MLA"
                                        ? "bg-gray-200 text-gray-800"
                                        : "bg-gray-100 text-gray-600"
                                        }`}
                                    onClick={() =>
                                        setCitationPopup({ ...citationPopup, selectedTab: "MLA" })
                                    }
                                >
                                    MLA
                                </div>
                                <div
                                    className={`py-2 px-4 flex justify-center cursor-pointer ${citationPopup.selectedTab === "Chicago"
                                        ? "bg-gray-200 text-gray-800"
                                        : "bg-gray-100 text-gray-600"
                                        }`}
                                    onClick={() =>
                                        setCitationPopup({ ...citationPopup, selectedTab: "Chicago" })
                                    }
                                >
                                    Chicago
                                </div>
                                <div
                                    className={`py-2 px-4 flex justify-center cursor-pointer ${citationPopup.selectedTab === "BibTeX"
                                        ? "bg-gray-200 text-gray-800"
                                        : "bg-gray-100 text-gray-600"
                                        }`}
                                    onClick={() =>
                                        setCitationPopup({ ...citationPopup, selectedTab: "BibTeX" })
                                    }
                                >
                                    BibTeX
                                </div>
                            </div>
                            <div className="text-gray-600 rounded bg-gray-100 text-sm light p-6 relative">
                                <div
                                    className="citation-text font-sans break-words"
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            citationPopup.selectedTab === "APA"
                                                ? citationPopup.citationAPA
                                                : citationPopup.selectedTab === "MLA"
                                                    ? citationPopup.citationMLA
                                                    : citationPopup.selectedTab === "Chicago"
                                                        ? citationPopup.citationChicago
                                                        : citationPopup.citationBibTeX,
                                    }}
                                ></div>
                                <PiCopyBold
                                    className="my-2 w-5 h-5 cursor-pointer hover:text-gray-800 text-gray-600"
                                    onClick={() =>
                                        copyToClipboard(
                                            citationPopup[`citation${citationPopup.selectedTab}`]
                                        )
                                    }
                                    title="Copy to clipboard"
                                />
                                {showCopiedMessage && (
                                    <div className="absolute bottom-2 border rounded-full left-1/2 transform -translate-x-1/2 bg-white border-green-500 text-green-500 px-5 py-2 rounded">
                                        {selectedLanguage === "es-ES" ? "Texto copiado" : "Text copied"}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {recommendations?.map((paper, index) => (
                <div key={index} className='flex justify-center'>
                    <div className='p-4 w-11/12 sm:w-4/5 rounded-xl bg-white my-3 shadow-2xl'>
                        <div className='flex flex-col w-full justify-evenly'>
                            <a className='hover:underline w-full py-2 flex flex-col sm:flex-row items-start sm:items-center'>
                                <div className='w-full sm:ml-4'>
                                    <p onClick={() => handleDocumentSelection(paper)} className='text-gray-500 text-sm cursor-pointer line-clamp-3 pr-2'>{paper?.translatedTitle}</p>
                                </div>
                            </a>
                        </div>
                        <div className=''>
                            {(paper?.tldr?.text || paper?.translatedAbstract) &&
                                <div className='flex w-full justify-center'>
                                    <div className='w-full sm:w-11/12 my-2 rounded-xl p-2 bg-gray-100'>
                                        <p style={expandedPapers[index] ? {} : {
                                            display: '-webkit-box',
                                            WebkitBoxOrient: 'vertical',
                                            WebkitLineClamp: '3',
                                            overflow: 'hidden'
                                        }} className='text-gray-800 font-sans text-justify'>
                                            {paper?.tldr?.text || paper?.translatedAbstract}
                                        </p>
                                        <button onClick={() => toggleText(index)} className='text-blue-500 font-sans'>
                                            {expandedPapers[index] ? selectedLanguage === "es-ES" ? "Ver menos" : "See less" : selectedLanguage === "es-ES" ? "Ver más" : "See more"}
                                        </button>
                                    </div>
                                </div>}
                            <div className='flex justify-center pt-3 pb-6'>
                                <div className='w-full sm:w-11/12 sm:flex'>
                                    <div className='w-full sm:w-1/2 flex justify-start'><a className='text-gray-500 flex items-center font-semibold pb-2 text-sm'>{paper?.journal?.name} <p>{topJournals.includes(paper?.journal?.name) && <a className='flex items-center ml-2'>{selectedLanguage === "es-ES" ? <a className='flex items-center'> <PiSparkleBold className='w-6 h-6 text-yellow-500 mr-1 font-semibold' /> <p className='font-semibold text-gray-600'>Revista influyente</p></a> : <a className='flex'> <PiSparkleBold className='mr-1 w-6 h-6 text-yellow-500' /> <p className='text-gray-600 font-semibold'>Influential Journal</p> </a>}</a>}</p> </a></div>
                                    <div className='flex w-full sm:w-1/2'>
                                        {paper?.author &&
                                            <div className='w-1/2 flex justify-start pr-2'>
                                                <p className='text-gray-500 text-sm font-light italic'>
                                                    {paper?.author ? paper.author.split(',')[0] + " et al" : ''}
                                                </p>
                                            </div>}
                                        {(paper?.citationCount || paper?.year) &&
                                            <><div className='w-1/3 flex justify-center px-2'>
                                                {paper?.citationCount &&
                                                    <p className='text-gray-500 text-sm'>
                                                        {paper?.citationCount} {selectedLanguage === "es-ES" ? "Citas" : "Citations"}
                                                    </p>}
                                            </div>
                                                <div className='w-1/6 flex justify-center'>
                                                    <p className='text-gray-500 text-sm'>{paper?.year}</p>
                                                </div></>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="border-t px-1 sm:px-6 flex border-gray-300">
                                <div className="w-1/2 sm:w-4/5 flex justify-evenly items-center py-5">
                                    <div className='w-2/3 sm:w-1/3'>
                                        {(paper?.openAccessPdf?.url) &&
                                            <button className='text-indigo-500 hover:bg-indigo-100 flex border-2 font-semibold border-indigo-500 px-5 py-2 rounded'
                                                onClick={(e) => user ? handlePaperSelection(e, paper) : signIn(e)}>
                                                <img src={sparkOne} className='w-6 h-6 hover:text-blue-600 text-blue-500 mr-1' />
                                                <a className='pb-1'>{selectedLanguage === "es-ES" ? "Chat" : "Chat"} </a> </button>}
                                    </div>
                                    <div className='hidden pl-16 sm:w-1/3 sm:flex justify-center'>
                                    </div></div>
                                <div className="w-1/2 sm:w-1/3 flex items-center py-5 justify-evenly">
                                    <a className='flex items-center'>
                                        {paper?.openAccessPdf?.url &&
                                            <a href={paper?.openAccessPdf?.url} target='_blank' className='text-sm flex text-gray-600 cursor-pointer font-semibold hover:underline'>
                                                <img src={pdfOne} className='w-6 h-auto text-red-500 mr-1' /> </a>}
                                    </a>
                                    <a className='flex items-center'>
                                        {(saved && savedPapers && savedPapers.paperId === paper.paperId) ? (
                                            <> <img src={checkOne} className="h-6 w-auto text-green-500" />
                                                <p className="pl-1 hidden sm:flex font-semibold hover:underline pb-1 text-sm text-gray-600">
                                                    {selectedLanguage === "es-ES" ? "Guardado" : "Saved"} </p> </>) : (
                                            <><img src={saveOne} onClick={() => handleSaving(paper)} className="h-6 cursor-pointer hover:text-green-700 w-auto text-green-500" />
                                                <p onClick={() => handleSaving(paper)} className="pl-1 pb-1 font-semibold hidden sm:flex hover:text-gray-700 hover:underline cursor-pointer text-sm text-gray-600">
                                                    {selectedLanguage === "es-ES" ? "Guardar" : "Save"}
                                                </p> </>)}
                                    </a>
                                    <a className='flex items-center'>
                                        <img src={quoteOne}
                                            onClick={() => showCitationPopup(paper)}
                                            className="h-6 hover:text-green-700 font-semibold cursor-pointer w-auto text-green-500" />
                                        <p onClick={() => showCitationPopup(paper)} className="pl-1 hidden sm:flex cursor-pointer hover:text-gray-700 hover:underline text-sm text-gray-600">{selectedLanguage === "es-ES" ? "Citar" : "Cite"}</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="fa-comment-icon">
                            <FaCommentAlt className="w-6 h-6 text-blue-500" />
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}


const Sidebar = ({ sidebarRef, yearRange, yearBefore, yearAfter, setYearRange, setYearBefore, setYearAfter, handleCheckboxChange, selectedLanguage, free, year, setYear, handleSearch, setFree, isOpen, setIsOpen }) => {

    const [type, setType] = useState(false);
    const [field, setField] = useState(false);

    const handleCheckboxChangefree = (event) => {
        const isChecked = event.target.checked;
        setFree(isChecked ? "isOpenAccess" : "");
    };

    const toggleTypes = () => {
        setType(!type);
    };

    const toggleFields = () => {
        setField(!field);
    };

    const [years, setYears] = useState(false)

    const toggleYears = () => {
        setYears(!years)
    }

    const close = () => {
        setIsOpen(false);
    };

    return (
        <div
            ref={sidebarRef}
            className={`fixed inset-y-0 right-0 w-96 rounded-l-lg bg-gray-100 transform transition-transform overflow-y-auto ${isOpen ? 'translate-x-0' : '-translate-x-full'}`}>
            <div className="flex justify-center">
                <div className='w-5/6 flex pt-12 justify-between items-center'>
                    <p className="text-xl font-semibold text-gray-700">{selectedLanguage === "es-ES" ? "Filtros" : "Filters"}</p>
                    <XIcon onClick={close} className='text-gray-700 w-5 cursor-pointer h-5 hover:text-gray-900' />
                </div>
            </div>
            <div className="flex justify-center">
                <div className='w-5/6 pt-12 flex items-center'>
                    <input
                        onChange={handleCheckboxChangefree}
                        className='mr-2' type='checkbox'
                        checked={free === "isOpenAccess"}
                    />

                    <p className="text-gray-600">{selectedLanguage === "es-ES" ? "Acceso gratis" : "Free access"}</p>
                </div>
            </div>
            <div className="flex justify-center">
                <div onClick={toggleTypes} className='w-5/6 mt-8 cursor-pointer flex items-center pb-3 border-b border-gray-200 justify-between'>
                    <a className='flex items-center'> <IoSparklesSharp className='w-5 h-5 text-green-500 mr-2' /> <p className='text-gray-700 font-semibold'>{selectedLanguage === "es-ES" ? "Tipo de publicación" : "Publication type"}</p></a>
                    <FaChevronDown className='w-4 h-auto text-gray-600' />
                </div>
            </div>
            {type &&
                <>
                    <div className="flex items-center px-6 pt-4">
                        <input
                            value="Review"
                            onChange={(event) => handleCheckboxChange(event, 'publication')}
                            className='mr-2'
                            type='checkbox'
                        />
                        <p className="text-gray-700">Review</p>
                    </div>
                    <div className="flex items-center px-6 pt-4">
                        <input
                            value="JournalArticle"
                            onChange={(event) => handleCheckboxChange(event, 'publication')}
                            className='mr-2'
                            type='checkbox'
                        />
                        <p className="text-gray-700"> {selectedLanguage === "es-ES" ? "Artículo de Revista" : "Journal Article"}</p>
                    </div>
                    <div className="flex items-center px-6 pt-4">
                        <input
                            value="CaseReport"
                            onChange={(event) => handleCheckboxChange(event, 'publication')}
                            className='mr-2'
                            type='checkbox'
                        />
                        <p className="text-gray-700">{selectedLanguage === "es-ES" ? "Reporte de Caso" : "Case Report"}</p>
                    </div>
                    <div className="flex items-center px-6 pt-4">
                        <input
                            value="ClinicalTrial"
                            onChange={(event) => handleCheckboxChange(event, 'publication')}
                            className='mr-2'
                            type='checkbox'
                        />
                        <p className="text-gray-700">{selectedLanguage === "es-ES" ? "Ensayo clínico" : "Clinical Trial"}</p>
                    </div>
                    <div className="flex items-center px-6 pt-4">
                        <input
                            value="Dataset"
                            onChange={(event) => handleCheckboxChange(event, 'publication')}
                            className='mr-2'
                            type='checkbox'
                        />
                        <p className="text-gray-700">Dataset</p>
                    </div>
                    <div className="flex items-center px-6 pt-4">
                        <input
                            value="Editorial"
                            onChange={(event) => handleCheckboxChange(event, 'publication')}
                            className='mr-2'
                            type='checkbox'
                        />
                        <p className="text-gray-700">Editorial</p>
                    </div>
                    <div className="flex items-center px-6 pt-4">
                        <input
                            value="LettersAndComments"
                            onChange={(event) => handleCheckboxChange(event, 'publication')}
                            className='mr-2'
                            type='checkbox'
                        />
                        <p className="text-gray-700">{selectedLanguage === "es-ES" ? "Cartas y Comentario" : "Letters And Comments"}</p>
                    </div>
                    <div className="flex items-center px-6 pt-4">
                        <input
                            value="MetaAnalysis"
                            onChange={(event) => handleCheckboxChange(event, 'publication')}
                            className='mr-2'
                            type='checkbox'
                        />
                        <p className="text-gray-700">{selectedLanguage === "es-ES" ? "Meta Análisis" : "Meta Analysis"}</p>
                    </div>
                    <div className="flex items-center px-6 pt-4">
                        <input
                            value="News"
                            onChange={(event) => handleCheckboxChange(event, 'publication')}
                            className='mr-2'
                            type='checkbox'
                        />
                        <p className="text-gray-700">{selectedLanguage === "es-ES" ? "Noticia" : "News"}</p>
                    </div>
                    <div className="flex items-center px-6 pt-4">
                        <input
                            value="Study"
                            onChange={(event) => handleCheckboxChange(event, 'publication')}
                            className='mr-2'
                            type='checkbox'
                        />
                        <p className="text-gray-700">{selectedLanguage === "es-ES" ? "Estudio" : "Study"}</p>
                    </div>
                    <div className="flex items-center px-6 pt-4">
                        <input
                            value="Book"
                            onChange={(event) => handleCheckboxChange(event, 'publication')}
                            className='mr-2'
                            type='checkbox'
                        />
                        <p className="text-gray-700">{selectedLanguage === "es-ES" ? "Libro" : "Book"}</p>
                    </div>
                    <div className="flex items-center px-6 pt-4">
                        <input
                            value="BookSection"
                            onChange={(event) => handleCheckboxChange(event, 'publication')}
                            className='mr-2'
                            type='checkbox'
                        />
                        <p className="text-gray-700">{selectedLanguage === "es-ES" ? "Sección de Libro" : "Book Section"}</p>
                    </div>
                </>
            }
            <div className="flex justify-center">
                <div onClick={toggleFields} className='w-5/6 mt-8 cursor-pointer flex items-center pb-3 border-b border-gray-200 justify-between'>
                    <a className='flex items-center'> <IoSparklesSharp className='w-5 h-5 text-green-500 mr-2' /> <p className='text-gray-700 font-semibold'>{selectedLanguage === "es-ES" ? "Áreas de estudio" : "Fields of study"}</p></a>
                    <FaChevronDown className='w-4 h-auto text-gray-600' />
                </div>
            </div>
            {field &&
                <>
                    <div className="flex items-center px-6 pt-4">
                        <input
                            value="Computer Science"
                            onChange={(event) => handleCheckboxChange(event, 'field')}
                            className='mr-2'
                            type='checkbox'
                        />
                        <p className="text-gray-700">{selectedLanguage === "es-ES" ? "Ciencias de la Computación" : "Computer Science"}</p>
                    </div>
                    <div className="flex items-center px-6 pt-4">
                        <input
                            value="Medicine"
                            onChange={(event) => handleCheckboxChange(event, 'field')}
                            className='mr-2'
                            type='checkbox'
                        />
                        <p className="text-gray-700">{selectedLanguage === "es-ES" ? "Medicina" : "Medicine"}</p>
                    </div>
                    <div className="flex items-center px-6 pt-4">
                        <input
                            value="Chemistry"
                            onChange={(event) => handleCheckboxChange(event, 'field')}
                            className='mr-2'
                            type='checkbox'
                        />
                        <p className="text-gray-700">{selectedLanguage === "es-ES" ? "Química" : "Chemistry"}</p>
                    </div>
                    <div className="flex items-center px-6 pt-4">
                        <input
                            value="Biology"
                            onChange={(event) => handleCheckboxChange(event, 'field')}
                            className='mr-2'
                            type='checkbox'
                        />
                        <p className="text-gray-700">{selectedLanguage === "es-ES" ? "Biología" : "Biology"}</p>
                    </div>
                    <div className="flex items-center px-6 pt-4">
                        <input
                            value="Materials Science"
                            onChange={(event) => handleCheckboxChange(event, 'field')}
                            className='mr-2'
                            type='checkbox'
                        />
                        <p className="text-gray-700">{selectedLanguage === "es-ES" ? "Ciencia de los Materiales" : "Materials Science"}</p>
                    </div>
                    <div className="flex items-center px-6 pt-4">
                        <input
                            value="Physics"
                            onChange={(event) => handleCheckboxChange(event, 'field')}
                            className='mr-2'
                            type='checkbox'
                        />
                        <p className="text-gray-700">{selectedLanguage === "es-ES" ? "Física" : "Physics"}</p>
                    </div>
                    <div className="flex items-center px-6 pt-4">
                        <input
                            value="Geology"
                            onChange={(event) => handleCheckboxChange(event, 'field')}
                            className='mr-2'
                            type='checkbox'
                        />
                        <p className="text-gray-700">{selectedLanguage === "es-ES" ? "Geología" : "Geology"}</p>
                    </div>
                    <div className="flex items-center px-6 pt-4">
                        <input
                            value="Psychology"
                            onChange={(event) => handleCheckboxChange(event, 'field')}
                            className='mr-2'
                            type='checkbox'
                        />
                        <p className="text-gray-700">{selectedLanguage === "es-ES" ? "Psicología" : "Psychology"}</p>
                    </div>
                    <div className="flex items-center px-6 pt-4">
                        <input
                            value="Art"
                            onChange={(event) => handleCheckboxChange(event, 'field')}
                            className='mr-2'
                            type='checkbox'
                        />
                        <p className="text-gray-700">{selectedLanguage === "es-ES" ? "Arte" : "Art"}</p>
                    </div>
                    <div className="flex items-center px-6 pt-4">
                        <input
                            value="History"
                            onChange={(event) => handleCheckboxChange(event, 'field')}
                            className='mr-2'
                            type='checkbox'
                        />
                        <p className="text-gray-700">{selectedLanguage === "es-ES" ? "Historia" : "History"}</p>
                    </div>
                    <div className="flex items-center px-6 pt-4">
                        <input
                            value="Geography"
                            onChange={(event) => handleCheckboxChange(event, 'field')}
                            className='mr-2'
                            type='checkbox'
                        />
                        <p className="text-gray-700">{selectedLanguage === "es-ES" ? "Geografía" : "Geography"}</p>
                    </div>
                    <div className="flex items-center px-6 pt-4">
                        <input
                            value="Sociology"
                            onChange={(event) => handleCheckboxChange(event, 'field')}
                            className='mr-2'
                            type='checkbox'
                        />
                        <p className="text-gray-700">{selectedLanguage === "es-ES" ? "Sociología" : "Sociology"}</p>
                    </div>
                    <div className="flex items-center px-6 pt-4">
                        <input
                            value="Business"
                            onChange={(event) => handleCheckboxChange(event, 'field')}
                            className='mr-2'
                            type='checkbox'
                        />
                        <p className="text-gray-700">{selectedLanguage === "es-ES" ? "Empresas" : "Business"}</p>
                    </div>
                    <div className="flex items-center px-6 pt-4">
                        <input
                            value="Political Science"
                            onChange={(event) => handleCheckboxChange(event, 'field')}
                            className='mr-2'
                            type='checkbox'
                        />
                        <p className="text-gray-700">{selectedLanguage === "es-ES" ? "Ciencias Políticas" : "Political Science"}</p>
                    </div>
                    <div className="flex items-center px-6 pt-4">
                        <input
                            value="Economics"
                            onChange={(event) => handleCheckboxChange(event, 'field')}
                            className='mr-2'
                            type='checkbox'
                        />
                        <p className="text-gray-700">{selectedLanguage === "es-ES" ? "Economía" : "Economics"}</p>
                    </div>
                    <div className="flex items-center px-6 pt-4">
                        <input
                            value="Philosophy"
                            onChange={(event) => handleCheckboxChange(event, 'field')}
                            className='mr-2'
                            type='checkbox'
                        />
                        <p className="text-gray-700">{selectedLanguage === "es-ES" ? "Filosofía" : "Philosophy"}</p>
                    </div>
                    <div className="flex items-center px-6 pt-4">
                        <input
                            value="Mathematics"
                            onChange={(event) => handleCheckboxChange(event, 'field')}
                            className='mr-2'
                            type='checkbox'
                        />
                        <p className="text-gray-700">{selectedLanguage === "es-ES" ? "Matemáticas" : "Mathematics"}</p>
                    </div>
                    <div className="flex items-center px-6 pt-4">
                        <input
                            value="Engineering"
                            onChange={(event) => handleCheckboxChange(event, 'field')}
                            className='mr-2'
                            type='checkbox'
                        />
                        <p className="text-gray-700">{selectedLanguage === "es-ES" ? "Ingeniería" : "Engineering"}</p>
                    </div>
                    <div className="flex items-center px-6 pt-4">
                        <input
                            value="Environmental Science"
                            onChange={(event) => handleCheckboxChange(event, 'field')}
                            className='mr-2'
                            type='checkbox'
                        />
                        <p className="text-gray-700">{selectedLanguage === "es-ES" ? "Ciencias Ambientales" : "Environmental Science"}</p>
                    </div>
                    <div className="flex items-center px-6 pt-4">
                        <input
                            value="Agricultural and Food Sciences"
                            onChange={(event) => handleCheckboxChange(event, 'field')}
                            className='mr-2'
                            type='checkbox'
                        />
                        <p className="text-gray-700">{selectedLanguage === "es-ES" ? "Ciencias de la Agricultura y Alimentos" : "Agricultural and Food Sciences"}</p>
                    </div>
                    <div className="flex items-center px-6 pt-4">
                        <input
                            value="Education"
                            onChange={(event) => handleCheckboxChange(event, 'field')}
                            className='mr-2'
                            type='checkbox'
                        />
                        <p className="text-gray-700">{selectedLanguage === "es-ES" ? "Educación" : "Education"}</p>
                    </div>
                    <div className="flex items-center px-6 pt-4">
                        <input
                            value="Law"
                            onChange={(event) => handleCheckboxChange(event, 'field')}
                            className='mr-2'
                            type='checkbox'
                        />
                        <p className="text-gray-700">{selectedLanguage === "es-ES" ? "Leyes" : "Law"}</p>
                    </div>
                    <div className="flex items-center px-6 pt-4">
                        <input
                            value="Linguistics"
                            onChange={(event) => handleCheckboxChange(event, 'field')}
                            className='mr-2'
                            type='checkbox'
                        />
                        <p className="text-gray-700">{selectedLanguage === "es-ES" ? "linguística" : "Linguistics"}</p>
                    </div>
                </>
            }
            <div className="flex justify-center">
                <div onClick={toggleYears} className='w-5/6 mt-8 cursor-pointer flex items-center pb-3 border-b border-gray-200 justify-between'>
                    <a className='flex items-center'> <IoSparklesSharp className='w-5 h-5 text-green-500 mr-2' /> <p className='text-gray-700 font-semibold'>{selectedLanguage === "es-ES" ? "Años" : "Years"}</p></a>
                    <FaChevronDown className='w-4 h-auto text-gray-600' />
                </div>
            </div>
            {years &&
                <div className='px-6 pt-4'>
                    <div className="flex items-center px-6 pt-6">
                        <p className="text-gray-700">{selectedLanguage === "es-ES" ? "Significa: Durante 2019" : "Meaning: During 2019"}</p>
                    </div>
                    <div className="flex items-center px-6 pt-4">
                        <input
                            value={year}
                            onChange={(e) => { setYear(e.target.value) }}
                            placeholder={selectedLanguage === "es-ES" ? "2019" : "2019"}
                            className='mr-2 w-1/2 p-2 rounded outline-none border border-gray-300'
                        />
                    </div>
                    <div className="flex items-center px-6 pt-6">
                        <p className="text-gray-700">{selectedLanguage === "es-ES" ? "Significa: Entre 2015 y 2020" : "Meaning: Between 2015 and 2020"}</p>
                    </div>
                    <div className="flex items-center px-6 pt-4">
                        <input
                            value={yearRange}
                            onChange={(e) => { setYearRange(e.target.value) }}
                            className='mr-2 w-1/2 p-2 rounded outline-none border border-gray-300'
                            placeholder={selectedLanguage === "es-ES" ? "2015-2020" : "2015-2020"}
                        />
                    </div>
                    <div className="flex items-center px-6 pt-6">
                        <p className="text-gray-700">{selectedLanguage === "es-ES" ? "Significa: Durante o después del 2010" : "Meaning: During or after 2010"}</p>
                    </div>
                    <div className="flex items-center px-6 pt-4">
                        <input
                            value={yearAfter}
                            onChange={(e) => { setYearAfter(e.target.value) }}
                            className='mr-2 w-1/2 p-2 rounded outline-none border border-gray-300'
                            placeholder={selectedLanguage === "es-ES" ? "2010-" : "2010-"}
                        />
                    </div>
                    <div className="flex items-center px-6 pt-6">
                        <p className="text-gray-700">{selectedLanguage === "es-ES" ? "Significa: Durante o antes del 2013" : "Meaning: During or before 2013"}</p>
                    </div>
                    <div className="flex items-center px-6 pt-4">
                        <input
                            value={yearBefore}
                            onChange={(e) => { setYearBefore(e.target.value) }}
                            className='mr-2 w-1/2 p-2 rounded outline-none border border-gray-300'
                            placeholder={selectedLanguage === "es-ES" ? "-2013" : "-2013"}
                        />
                    </div>
                </div>
            }
            <div className='py-6 flex justify-center'>
                <button onClick={handleSearch} className='px-5 py-2 rounded bg-blue-600 hover:bg-blue-700 text-white font-semibold'>{selectedLanguage === "es-ES" ? "Buscar" : "Search"}</button>
            </div>
        </div>
    );
};